// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

import Buying from "../src/views/Tables/Buying";
import BulkUpdateResults from "../src/views/Tables/BulkUpdateResults";
import GlobalUpdate from "../src/views/Tables/GlobalUpdate"
import Dashboard from "../src/views/Dashboard/Dashboard.jsx";
import ErrorPage from "../src/views/Pages/ErrorPage.jsx";
import GoatProducts from "../src/views/Tables/GoatProducts.jsx";
import GoatStockxProducts from "../src/views/Tables/GoatStockxProducts.jsx";
import History from "../src/views/Tables/History.jsx";
import NotFound from "../src/views/Pages/NotFound.jsx";
import Pending from "../src/views/Tables/Pending.jsx";
import ProductList from "../src/views/Tables/ProductList.jsx";
import Proxy from "../src/views/Pages/Proxy.jsx";
import Scheduler from "../src/views/Pages/Scheduler.jsx";
import Brand from "../src/views/Tables/Brand.jsx";
import GoatBrand from "../src/views/Tables/GoatBrand.jsx";
import StockXBuyNow from "./views/Tables/StockXBuyNow.jsx";
import ModelingSetting from "./views/Tables/ModelingSetting.jsx";
import PlatformSetting from "./views/Tables/PlatformSetting.jsx";



var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/buying",
    name: "Buying",
    rtlName: "لوحة القيادة",
    icon: "shopping_cart",
    component: Buying,
    layout: "/admin"
  },
  {
    path: "/platform-settings",
    name: "Platform Settings",
    rtlName: "Platform Settings",
    icon: "settings_suggest_icon",
    component: PlatformSetting,
    layout: "/admin"
  },
  {
    path: "/error",
    name: "Error",
    rtlName: "لوحة القيادة",
    icon: "add_shopping_cart",
    component: ErrorPage,
    layout: "/admin"
  },
  {
    path: "/stock-x-brands",
    name: "StockX Brands",
    rtlName: "StockX Brands",
    icon: "event_note",
    component: Brand,
    layout: "/admin",
    q: '?site=1',
  },
  {
    path: "/products",
    name: "StockX Products",
    rtlName: "لوحة القيادة",
    icon: "add_shopping_cart",
    component: ProductList,
    layout: "/admin"
  },
  {
    path: "/stock-x-buy-now",
    name: "StockX Buy Now",
    rtlName: "StockX Buy Now",
    icon: "compare_icon",
    component: StockXBuyNow,
    layout: "/admin"
  },
  {
    path: "/pending",
    name: "Pending",
    rtlName: "لوحة القيادة",
    icon: "hourglass_empty",
    component: Pending,
    layout: "/admin"
  },
  {
    path: "/history",
    name: "History",
    rtlName: "لوحة القيادة",
    icon: "history",
    component: History,
    layout: "/admin"
  },
  {
    path: "/proxy",
    name: "Proxy",
    rtlName: "لوحة القيادة",
    icon: "settings_ethernet",
    component: Proxy,
    layout: "/admin"
  },
  {
    path: "/scheduler",
    name: "Scrapy Scheduler",
    rtlName: "لوحة القيادة",
    icon: "event_note",
    component: Scheduler,
    layout: "/admin"
  },
  {
    path: "/goat-brands",
    name: "Goat Brands",
    rtlName: "Goat Brands",
    icon: "event_note",
    component: GoatBrand,
    layout: "/admin",
  },
  {
    path: "/goat-products",
    name: "Goat Products",
    rtlName: "لوحة القيادة",
    icon: "shopping_basket",
    component: GoatProducts,
    layout: "/admin"
  },
  {
    path: "/bulk-update-results",
    name: "Bulk Update",
    rtlName: "لوحة القيادة",
    icon: "dynamic_feed_icon",
    component: BulkUpdateResults,
    layout: "/admin"
  },
  {
    path: "/Global-update-results",
    name: "Global Update",
    rtlName: "لوحة القيادة",
    icon: "public",
    component: GlobalUpdate,
    layout: "/admin"
  },
  {
    path: "/product-mapping",
    name: "Goat Vs Stockx",
    rtlName: "لوحة القيادة",
    icon: "compare_icon",
    component: GoatStockxProducts,
    layout: "/admin"
  },
  {
    path: "/modeling-settings",
    name: "Modeling Settings",
    rtlName: "Modeling Settings",
    icon: "settings_icon",
    component: ModelingSetting,
    layout: "/admin"
  },
  {
    path: "*",
    name: "NotFound",
    rtlName: "لوحة القيادة",
    icon: "notFound",
    component: NotFound,
    layout: "/admin"
  },
];

export default dashRoutes;
