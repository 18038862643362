import React, { useReducer, useState, useEffect } from "react";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import {
  PagingState,
  CustomPaging,
  EditingState,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-grid";

import {
  Grid,
  Toolbar,
  TableHeaderRow,
  TableGroupRow,
  PagingPanel,
  VirtualTable,
  TableEditRow,
  TableEditColumn,
  ColumnChooser,
  TableColumnVisibility,
} from "@devexpress/dx-react-grid-bootstrap4";

import Cancel from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Save from "@material-ui/icons/Save";
import Icon from "@material-ui/core/Icon";

import SweetAlert from "react-bootstrap-sweetalert";

import LoadingOverlay from "../../components/overlays/LoadingOverlay.jsx";
import Permission from "../../permission/Permission";

import {
  PLATFORM_SETTING_API_URL
} from "../../configurations/configApi.jsx";

const initialState = {
  data: [],
  loading: false,
  totalCount: 0,
  errorMessage: "",
  successMessage: "",
};

function reducer(state, { type, payload }) {
  switch (type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: payload.results,
        totalCount: payload.count,
        loading: false
      };
    case "FETCH_ERROR":
      return {
        ...state,
        loading: false
      };
    case "LOADING":
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [columns] = useState([
    { name: "group",  title: "Group"},
    { name: "name", title: "Name"},
    { name: "value", title: "Dynamic Value" },
    { name: "updated_on", title: "Updated On"}
  ]);

  const editColumnMessages = {
    editCommand: <Edit title="Edit" />,
    commitCommand: <Save title="Save" />,
    cancelCommand: <Cancel title="Cancel" />,
  };

  const [pageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastQuery, setLastQuery] = useState();

  const [index, setIndex] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [sweetAlert, setSweetAlert] = useState("");
  const [rowChanges, setRowChanges] = useState({});

  const [editStateColumnExtensions] = useState([
    { columnName: "id", editingEnabled: false },
    { columnName: "name", editingEnabled: true },
    { columnName: "value", editingEnabled: true },
    { columnName: "updated_on", editingEnabled: false }
  ]); 

  const [grouping] = useState([{ columnName: 'group' }]);

  const GroupCell = ({ colSpan, ...restProps }) => {
    return <TableGroupRow.Cell {...restProps} colSpan={6} />;
  };

  const [groupingStateColumnExtensions] = useState([
    { columnName: "group", groupingEnabled: false },
    { columnName: "name", groupingEnabled: false },
    { columnName: "value", groupingEnabled: false },
    { columnName: "updated_on", groupingEnabled: false }
  ])

  const getRowId = (row) => {
    return row.id;
  };

  const updateSettings=(id, data)=>{
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    axios(PLATFORM_SETTING_API_URL + id + '/', {
      method: "patch",
      headers,
      data
    })
    .then(() => {
        fetchData()
    })
    .catch(error => {
      Sentry.captureException("Setting API " + error);
      dispatch({ type: "FETCH_ERROR" });
      const sweet = (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => setSweetAlert("")}
          confirmBtnText="OK"
        >
          <h4>Platform Setting - Error in update data!</h4>
        </SweetAlert>
      );
      setSweetAlert(sweet);
    });
  }

  const commitChanges = ({ changed }) => {
    let payload = {}, changedRow;
    let { data: rows } = state
    if (changed) {
      changedRow = rows.find(row => changed[row.id])
      if (changedRow) {
        payload = {
          ...changed[changedRow.id]
        }
      }
    }
    
    if (changedRow) {
      updateSettings(changedRow.id, payload)
    }
  };

  const { data, loading, totalCount } = state;

  // to show this message, before api for listing is called
  const tableMessages = {
    noData: "No Data Found"
  };

  // function for setting current page
  const setNowPage = val => {
    setIndex(index + 1);
    setCurrentPage(val);
  };

  // if no error resp or status seems 404 or 500, need to redirect to error page
  const checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
    ) {
      return true;
    }
  };

  const getQueryString = () => {
    return `${PLATFORM_SETTING_API_URL}?page=${currentPage + 1}&limit=${pageSize}`;
  };

  const fetchData = () => {
    const queryString = getQueryString();
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    fetch(queryString, {
        method: "get",
        headers
      })
      .then(response => response.json())
      .then(data => {
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      })

      .catch(error => {
        Sentry.captureException("Platform Setting API " + error);
        if (checkFunction(error)) {
          window.location = "/admin/error";
        } else {
          dispatch({ type: "FETCH_ERROR" });
          const sweet = (
            <SweetAlert
              style={{ display: "block", marginTop: "-100px" }}
              title="Error"
              onConfirm={() => setSweetAlert("")}
              confirmBtnText="OK"
            >
              <h4>Platform Setting - Error in fetching data!</h4>
            </SweetAlert>
          );
          setSweetAlert(sweet);
        }
      });
  };

  // for loading the data
  const loadData = () => {
    const queryString = getQueryString();
    setInitialLoad(false);

    if (queryString !== lastQuery && !loading) {
      fetchData();
      setLastQuery(queryString);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      var projections = document.getElementsByTagName("body")[0];
      projections.classList.remove("projectIcon");
      var testElements = document.getElementsByTagName("body")[0];
      testElements.classList.remove("hideAddition");
    }
    loadData();
  });

  return (
    <>
      {sweetAlert}
      <div className="card settings" style={{ position: "relative" }}>
        <Permission />
        <span className="reload" title="Reload">
          <Icon color="secondary" onClick={() => fetchData()} title="Reload">
            refresh
          </Icon>
        </span>
        <Grid rows={data} columns={columns} getRowId={getRowId}>
          <PagingState
            currentPage={currentPage} // show current page, first time it's set to 1
            onCurrentPageChange={setNowPage} // to change current pa  ge
            pageSize={pageSize} // set page limit to show in one page
          />
          <CustomPaging totalCount={totalCount} />
          <EditingState
            rowChanges={rowChanges}
            onRowChangesChange={setRowChanges}
            onCommitChanges={commitChanges}
            columnExtensions={editStateColumnExtensions} // to enable the columns to edit
          />
          <GroupingState
            grouping={grouping}
            columnExtensions={groupingStateColumnExtensions}
          />
          <IntegratedGrouping />
          <VirtualTable messages={data.length === 0 && tableMessages} />
          <TableHeaderRow />
          <TableGroupRow 
            cellComponent={GroupCell}
          />
          <TableEditRow />
          <TableEditColumn showEditCommand messages={editColumnMessages}  width={50} />
          <Toolbar />
          <TableColumnVisibility/>
          <ColumnChooser />
          {data.length > 0 && <PagingPanel />}
        </Grid>
        <LoadingOverlay showOverlay={loading} />
      </div>
    </>
  );
};
