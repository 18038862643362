import React, { useState, useEffect, useReducer } from "react";
import * as Sentry from "@sentry/browser";
import NumberFormat from "react-number-format";
import axios from "axios";

import {
  CustomPaging,
  DataTypeProvider,
  FilteringState,
  PagingState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  ColumnChooser,
  DragDropProvider,
  Grid,
  PagingPanel,
  TableColumnVisibility,
  TableHeaderRow,
  TableFilterRow,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-bootstrap4";
import Icon from "@material-ui/core/Icon";

import Permission from "../../permission/Permission";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "../../components/overlays/LoadingOverlay.jsx";
import { STOCKX_BUY_NOW_API_URL, SYNC_STOCK_X_BUY_NOW_API_URL } from "../../configurations/configApi";
import "react-datepicker/dist/react-datepicker.css";

const URL = STOCKX_BUY_NOW_API_URL;
let setEnterKeyForNameEditor = false;


const getRowId = (row) => {
  return row.id;
};

const NameEditor = ({ value, onValueChange, row, column }) => {
  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === "v") {
      setEnterKeyForNameEditor = true;
    } else if (event.ctrlKey && charCode === "x") {
      setEnterKeyForNameEditor = true;
    }
  };

  const handleKey = (event) => {
    if (event.which === 13) {
      setEnterKeyForNameEditor = true;
      onValueChange(value);
    }
  };

  const handleChange = (event) => {
    if (event.target.value === "") {
      setEnterKeyForNameEditor = true;
      onValueChange(event.target.value);
    }
    const { value: targetValue } = event.target;
    onValueChange(targetValue);
  };

  return row ? (
    <p>{row[column.name]}</p>
  ) : (
    <input
      className="form-control text-right"
      type={
        ["sku", "name", "lowest_vendor", "brand"].includes(column.name)
          ? "text"
          : "number"
      }
      tabIndex="0"
      placeholder="Filter..."
      value={value === undefined ? "" : value}
      min={0}
      onChange={handleChange}
      onKeyPress={(e) => handleKey(e)}
      onKeyDown={(e) => handleKeyDown(e)}
    />
  );
};

function reducer(state, { type, payload }) {
  const { grouping, expandedGroups, tempGrouping } = state;

  switch (type) {
    case "CHANGE_GROUPING":
      return {
        ...state,
        loading: true,
        grouping: payload,
        tempGrouping: tempGrouping === null ? grouping : tempGrouping,
        tempExpandedGroups: expandedGroups
      };
    case "SET_EXPANDED_GROUPS":
      return {
        ...state,
        expandedGroups: payload,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: payload.results,
        totalCount: payload.count,
        totalChildCount: payload.variant_count,
        tempExpandedGroups: null,
        loading: false,
        syncCeleryRun : payload.sync_stock_x_buy_now

      };
    case "FETCH_ERROR":
      return {
        ...state,
        loading: false,
      };
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

const initialState = {
  data: [],
  loading: false,
  totalCount: 0,
  syncCeleryRun: null
};

const tableMessages = {
  noData: "No Data Found",
};

const FilterIcon = ({ type }) => {
  if (type === "in") {
    return <span className="d-block oi oi-magnifying-glass" />;
  } else if (type === "doesNotIn") {
    return <span className="d-block oi oi-magnifying-glass cross" />;
  }
  return <TableFilterRow.Icon type={type} />;
};

const filterMessages = {
  in: "In",
  doesNotIn: "Not In",
};

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [columns] = useState([
    { name: "goat_image", title: "Image" },
    { name: "name", title: "Item" },
    { name: "brand", title: "Brand" },
    { name: "size", title: "Size" },
    { name: "goat_price", title: "Goat Price" },
    { name: "stockx_price", title: "Stockx Price" },
    { name: "lowest_vendor", title: "Lowest Vendor" },
    { name: "modeling_price_difference_percentage", title: "Price Difference(%)" },
    { name: "sku", title: "SKU" },
    { name: "goat_last_sale", title: "Goat Last Sale" },
    { name: "stockx_last_sale", title: "Stockx Last Sale" },
    { name: "number_of_bids", title: "No of Bids" },
    { name: "number_Of_asks", title: "No of Asks" },
  ]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [lastQuery, setLastQuery] = useState();
  const [lowestVendorColumns] = useState(["lowest_vendor"]);
  const [imageColumns] = useState(["goat_image"]);
  const [skuColumns] = useState(["sku"]);
  const [nameColumns] = useState(["name"]);
  const [brandColumns] = useState(["brand"]);
  const [priceDifferencePercentageColumns] = useState([
    "modeling_price_difference_percentage",
  ]);
  const [sizeColumns] = useState(["size"]);
  const [priceColumns] = useState(["stockx_price", "goat_price"]);
  const [numberOfBidsColumns] = useState(["number_of_bids"]);
  const [numberOfAsksColumns] = useState(["number_Of_asks"]);
  const [goatLastSaleColumns] = useState(["goat_last_sale"]);
  const [stockxLastSaleColumns] = useState(["stockx_last_sale"]);
  const [pageSize] = useState(30); // to get the number of rows in each page
  const [currentPage, setCurrentPage] = useState(0); // to get current page
  const [sweetAlert, setSweetAlert] = useState("");
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [enterKeyValue, setEnterKeyValue] = useState([]);
  const [filterIndex, setFilterIndex] = useState(0);
  const [sortingStateColumnExtensions] = useState([
    { columnName: "goat_image", sortingEnabled: false },
  ]);
  const [filteringStateColumnExtensions] = useState([
    { columnName: "goat_image", filteringEnabled: false }
  ]);
  const [numberFilterOperations] = useState([
    "equal",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual",
  ]);
  const [nameFilterOperations] = useState([
    "contains",
    "notContains",
    "startsWith",
    "endsWith",
    "equal",
    "in",
    "doesNotIn",
  ]);

  const { data, loading, totalCount, syncCeleryRun } = state;

  const getCondition = (name, condition) => {
    let convertedCondition = ''

    switch (condition) {
      case 'contains':
        convertedCondition = ["name", "sku", "brand"].includes(name)
        ? `${
            name === "brand" ? "stockx_product" : "goat_product"
          }__parent_product__${name}__icontains`
        : `lowest_vendor__icontains`;
        break;

      case 'notContains':
        convertedCondition = ["name", "sku", "brand"].includes(name)
        ? `exclude|${
            name === "sku" ? "goat_product" : "stockx_product"
          }__parent_product__${name}__icontains`
        : `exclude|lowest_vendor__icontains`; 
        break;

      case 'startsWith':
        convertedCondition = ["name", "sku", "brand"].includes(name)
        ? `${
            name === "sku" ? "goat_product" : "stockx_product"
          }__parent_product__${name}__istartswith`
        : `lowest_vendor__istartswith`;
        break;
      
      case 'endsWith':
        convertedCondition = ["name", "sku", "brand"].includes(name)
        ? `${
            name === "sku" ? "goat_product" : "stockx_product"
          }__parent_product__${name}__istartswith`
        : `lowest_vendor__istartswith`;
        break;
      
      case 'equal':
        if (["name", "sku", "brand"].includes(name)) {
          convertedCondition = `${
            name === "sku" ? "goat_product" : "stockx_product"
          }__parent_product__${name}__iexact`;
        }

        if (["stockx_price", "number_of_bids", "number_Of_asks"].includes(name)) {
          convertedCondition = `${name}`
        }

        break;
      
      case 'in':
        convertedCondition = ["name", "sku", "brand"].includes(name)
        ? `${
            name === "sku" ? "goat_product" : "stockx_product"
          }__parent_product__${name}__in`
        : `lowest_vendor__in`;
        break;

      case 'doesNotIn':
        convertedCondition = ["name", "sku", "brand"].includes(name)
        ? `exclude|${
            name === "sku" ? "goat_product" : "stockx_product"
          }__parent_product__${name}__in`
        : `exclude|lowest_vendor__in`;
        break;
      
      case 'greaterThan':
        convertedCondition = (
          [
            "size",
            "goat_price",
            "stockx_price",
            "modeling_price_difference_percentage",
            "number_of_bids",
            "number_Of_asks",
            "goat_last_sale",
            "stockx_last_sale"
          ].includes(name) &&
          `${
            name === "modeling_price_difference_percentage"
              ? name
              : ["stockx_price", "number_of_bids", "number_Of_asks", "stockx_last_sale"].includes(name)
              ? "stockx"
              : "goat"
          }${name === "modeling_price_difference_percentage" ? "" : "_product__"}${
            name === "modeling_price_difference_percentage"
              ? ""
              : name === "goat_price"
              ? "lowest_price"
              : name === "stockx_price"
              ? "lowest_ask"
              : ["goat_last_sale", "stockx_last_sale"].includes(name)
              ? "last_sale"
              : name
          }__gt`
        );
        break;
      
      case 'greaterThanOrEqual':
        convertedCondition = (
          [
            "size",
            "goat_price",
            "stockx_price",
            "modeling_price_difference_percentage",
            "number_of_bids",
            "number_Of_asks",
            "goat_last_sale",
            "stockx_last_sale"
          ].includes(name) &&
          `${
            name === "modeling_price_difference_percentage"
              ? name
              : ["stockx_price", "number_of_bids", "number_Of_asks", "stockx_last_sale"].includes(name)
              ? "stockx"
              : "goat"
          }${name === "modeling_price_difference_percentage" ? "" : "_product__"}${
            name === "modeling_price_difference_percentage"
              ? ""
              : name === "goat_price"
              ? "lowest_price"
              : name === "stockx_price"
              ? "lowest_ask"
              : ["goat_last_sale", "stockx_last_sale"].includes(name)
              ? "last_sale"
              : name
          }__gte`
        );
        break;
      
      case 'lessThan':
        convertedCondition = (
          [
            "size",
            "goat_price",
            "stockx_price",
            "modeling_price_difference_percentage",
            "number_of_bids",
            "number_Of_asks",
            "goat_last_sale",
            "stockx_last_sale"
          ].includes(name) &&
          `${
            name === "modeling_price_difference_percentage"
              ? name
              : ["stockx_price", "number_of_bids", "number_Of_asks", "stockx_last_sale"].includes(name)
              ? "stockx"
              : "goat"
          }${name === "modeling_price_difference_percentage" ? "" : "_product__"}${
            name === "modeling_price_difference_percentage"
              ? ""
              : name === "goat_price"
              ? "lowest_price"
              : name === "stockx_price"
              ? "lowest_ask"
              : ["goat_last_sale", "stockx_last_sale"].includes(name)
              ? "last_sale"
              : name
          }__lt`
        );
        break;
      
      case 'lessThanOrEqual':
        convertedCondition = (
          [
            "size",
            "goat_price",
            "stockx_price",
            "modeling_price_difference_percentage",
            "number_of_bids",
            "number_Of_asks",
            "goat_last_sale",
            "stockx_last_sale"
          ].includes(name) &&
          `${
            name === "modeling_price_difference_percentage"
              ? name
              : ["stockx_price", "number_of_bids", "number_Of_asks", "stockx_last_sale"].includes(name)
              ? "stockx"
              : "goat"
          }${name === "modeling_price_difference_percentage" ? "" : "_product__"}${
            name === "modeling_price_difference_percentage"
              ? ""
              : name === "goat_price"
              ? "lowest_price"
              : name === "stockx_price"
              ? "lowest_ask"
              : ["goat_last_sale", "stockx_last_sale"].includes(name)
              ? "last_sale"
              : name
          }__lte`
        );
        break;
      default:
        convertedCondition = ["modeling_price_difference_percentage", "lowest_vendor"].includes(name)
        ? name
        : `${name === "stockx_price" ? "stockx" : "goat"}_product__${
            name === "goat_price"
              ? "lowest_price"
              : name === "stockx_price"
              ? "lowest_ask"
              : name
          }`;
        break;
    }

    return convertedCondition
  };

  const getQueryString = () => {
    let sortingStr;
    let queryString = `${URL}`;

    let filter = filters.reduce((acc, { columnName, value, operation }) => {
      let filterName = getCondition(columnName, operation);
      let filterObject = {};

      if (operation === "in" || operation === "doesNotIn") {
        var listIt = [];
        var splitValues = value.split(",");
        splitValues.forEach((each) => listIt.push(each.trim()));
        filterObject[filterName] = listIt;
      } else {
        filterObject[filterName] = encodeURIComponent(value.trim());
      }
      acc.push(filterObject);
      return acc;
    }, []);

    // check for sorting
    if (sorting.length) {
      const sortingConfig = sorting.map(({ columnName, direction }) => {
        // goat_product__parent_product__brand_name
        let colName = [
          "sku",
          "name",
          "brand",
          "size",
          "goat_price",
          "goat_last_sale",
          "stockx_price",
          "stockx_last_sale",
          "number_of_bids",
          "number_Of_asks"
        ].includes(columnName)
          ? `${
              ["stockx_price", "stockx_last_sale", "number_of_bids", "number_Of_asks"].includes(columnName)
                ? "stockx"
                : "goat"
            }_product__${
              [
                "size",
                "goat_price",
                "goat_last_sale",
                "stockx_price",
                "stockx_last_sale",
                "number_of_bids",
                "number_Of_asks"
              ].includes(columnName)
                ? ["goat_price", "stockx_price"].includes(columnName)
                  ? `lowest_${columnName === "goat_price" ? "price" : "ask"}`
                  : ["goat_last_sale", "stockx_last_sale"].includes(columnName)
                  ? "last_sale"
                  : columnName
                : `parent_product__${
                    columnName === "brand" ? "brand_name" : columnName
                  }`
            }`
          : columnName;
        return direction === "desc" ? `-${colName}` : colName;
      });
      sortingStr = JSON.stringify(sortingConfig);
    } else {
      sortingStr = JSON.stringify([""]);
    }

    return `${queryString}?page=${currentPage + 1}&filter=${JSON.stringify(
      filter
    )}&order_by=${sortingStr}`;
  };

  const fetchData = () => {
    const queryString = getQueryString();
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    fetch(queryString, {
      method: "get",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      })
      .catch((error) => {
        Sentry.captureException("StockX Buy Now Fetch API " + error);
        dispatch({ type: "FETCH_ERROR" });
        showSweetAlert()
      });
  };

  // for loading the data
  const loadData = () => {
    const queryString = getQueryString();
    setInitialLoad(false);

    if (queryString !== lastQuery && !loading) {
      fetchData();
      setLastQuery(queryString);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      setEnterKeyForNameEditor = false;
      var projections = document.getElementsByTagName("body")[0];
      projections.classList.remove("projectIcon");
      var testElements = document.getElementsByTagName("body")[0];
      testElements.classList.remove("hideAddition");
    }
    loadData();
  });

  const setFilterNameEditor = () => {
    setFilters(enterKeyValue);
    setCurrentPage(0);
    setEnterKeyForNameEditor = false;
  };

  const getFilters = (val) => {
    setFilterIndex(1);
    setEnterKeyValue(val);
  };

  // function for setting current page
  const setNowPage = (val) => {
    setFilterIndex(filterIndex + 1);
    setCurrentPage(val);
  };

  // sort function to override the setSorting method
  const setSort = (val) => {
    setSorting(val);
    setCurrentPage(0);
  };

  const showSweetAlert = (
    title = 'Error',
    message = 'StockX Buy Now - Error in update data!'
    ) => {
    const sweet = (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => setSweetAlert("")}
        confirmBtnText="OK"
      >
        <h4>{message}</h4>
      </SweetAlert>
    );
    setSweetAlert(sweet);
  }
  
  const syncStockXBuyNow = () => {
    if (syncCeleryRun && syncCeleryRun.is_active) {
      showSweetAlert('INFO', 'StockX Buy Now is updating. Pls Wait...')
      return
    }
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    axios(SYNC_STOCK_X_BUY_NOW_API_URL, {
      method: "post",
      headers,
    })
    .then(() => {
        fetchData()
    })
    .catch(error => {
      Sentry.captureException("UpdateBrand API " + error);
      dispatch({ type: "FETCH_ERROR" });
      showSweetAlert()
    });
  }

  const ImageFormatter = ({ value, row }) => {
    return (
      <div className="popImages">
        <img src={row["stockx_image"]} alt="" />
        <div>
          <a href={row["goat_url"]} target="_blank" rel="noopener noreferrer">
            <img src={value} alt="" />
          </a>
          <a href={row["stockx_url"]} target="_blank" rel="noopener noreferrer">
            <img src={row["stockx_image"]} alt="" />
          </a>
        </div>
      </div>
    );
  };

  const PriceFormatter = ({ column, value, row }) => {
    if (value) {
      return column.name === "stockx_price" ? (
        <p style={{ color: value < row["goat_price"] ? "#00cc00" : "black" }}>
          {value}
        </p>
      ) : (
        <p style={{ color: value < row["stockx_price"] ? "#00cc00" : "black" }}>
          {value}
        </p>
      );
    } else {
      return <p>{value}</p>;
    }
  };

  const LowestVendorFormatter = ({ value }) => (
    <p
      style={{
        color: value === "Stockx" || value === "Goat" ? "#00cc00" : "black",
      }}
    >
      {value}
    </p>
  );

  // to show custom formating last sale field
  const stockLastSaleFormatter = ({ value , row}) => {
    const last_sale_history = row['stockx_last_sale_history']
    return (
      <>
        { last_sale_history &&
          (last_sale_history.map((item, index)=>{
            return (<span>{index + 1} - {item['number_of_sale'] && (item['number_of_sale'])} <strong className="proxySuccess mr-2">{item['last_sale']} </strong> {item['updated_on']}<br/></span>)
          }))
        }
      </>
    );
  };

  const ModelingPriceFormatter = ({value, row}) => {
    const rating = row['rating'] ? row['rating']: 5
    const priceDiff = (value) ? value : row['price_difference_percentage']
    const classRating = "stock-x-rating stock-x-rating-" + rating
    return (
      <>
        <p className={classRating}>
          {priceDiff}
        </p>
      </>
    );
  }

  // to show custom formating last sale field
  const goatLastSaleFormatter = ({ value , row}) => {
    const last_sale_history = row['goat_last_sale_history']
    return (
      <>
        { last_sale_history &&
          (last_sale_history.map((item, index)=>{
            return (<span>{index + 1} - {item['number_of_sale'] && (item['number_of_sale'])} <strong className="proxySuccess mr-2">{item['last_sale']} </strong>  {item['updated_on']}<br/></span>)
          }))
        }
      </>
    );
  };

  return (
    <>
      {sweetAlert}
      <div className="goatstock">
        <Permission />
        <span className="syncBuyNowBtn reload" title="Sync StockX Buy Now">
          <Icon  onClick={syncStockXBuyNow} title="Sync StockX Buy Now" className={
            syncCeleryRun &&
            syncCeleryRun.is_active
              ? "rotate text-info"
              : ""
          }>cached</Icon>
        </span>
        <span className="reload " title="Reload">
          <Icon onClick={() => fetchData()} title="Reload">
            refresh
          </Icon>
        </span>
        {setEnterKeyForNameEditor ? setFilterNameEditor() : ""}
        <div className="card" style={{ position: "relative" }}>
          {totalCount > 0 && (
            <span title="Total Count" className="count goat">
              <NumberFormat
                value={totalCount}
                displayType={"text"}
                thousandSeparator={true}
              />
            </span>
          )}
          <Grid rows={data} columns={columns} getRowId={getRowId}>
            <SortingState
              sorting={sorting}
              onSortingChange={setSort}
              columnExtensions={sortingStateColumnExtensions}
            />
            <FilteringState
              onFiltersChange={getFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <PagingState
              currentPage={currentPage} // show current page, first time it's set to 1
              onCurrentPageChange={setNowPage} // to change current page
              pageSize={pageSize} // set page limit to show in one page
            />
            <CustomPaging totalCount={totalCount} />
            <DragDropProvider />
            <DataTypeProvider
              for={skuColumns}
              editorComponent={NameEditor}
              availableFilterOperations={nameFilterOperations}
            />
            <DataTypeProvider
              for={nameColumns}
              editorComponent={NameEditor}
              availableFilterOperations={nameFilterOperations}
            />
            <DataTypeProvider
              for={brandColumns}
              editorComponent={NameEditor}
              availableFilterOperations={nameFilterOperations}
            />
            <DataTypeProvider
              for={sizeColumns}
              editorComponent={NameEditor}
              availableFilterOperations={numberFilterOperations}
            />
            <DataTypeProvider
              for={imageColumns}
              formatterComponent={ImageFormatter}
            />
            <DataTypeProvider
              for={[priceColumns[0]]}
              formatterComponent={PriceFormatter}
              editorComponent={NameEditor}
              availableFilterOperations={numberFilterOperations}
            />
            <DataTypeProvider
              for={[priceColumns[1]]}
              formatterComponent={PriceFormatter}
              editorComponent={NameEditor}
              availableFilterOperations={numberFilterOperations}
            />
            <DataTypeProvider
              for={lowestVendorColumns}
              formatterComponent={LowestVendorFormatter}
              editorComponent={NameEditor}
              availableFilterOperations={nameFilterOperations}
            />
            <DataTypeProvider
              for={priceDifferencePercentageColumns}
              formatterComponent={ModelingPriceFormatter}
              editorComponent={NameEditor}
              availableFilterOperations={numberFilterOperations}
            />

            <DataTypeProvider
              for={numberOfBidsColumns}
              editorComponent={NameEditor}
              availableFilterOperations={numberFilterOperations}
            />

            <DataTypeProvider
              for={numberOfAsksColumns}
              editorComponent={NameEditor}
              availableFilterOperations={numberFilterOperations}
            />

            <DataTypeProvider
              for={goatLastSaleColumns}
              editorComponent={NameEditor}
              formatterComponent={goatLastSaleFormatter}
              availableFilterOperations={numberFilterOperations}
            />

            <DataTypeProvider
              for={stockxLastSaleColumns}
              editorComponent={NameEditor}
              formatterComponent={stockLastSaleFormatter}
              availableFilterOperations={numberFilterOperations}
            />

            <VirtualTable
              messages={data && data.length === 0 && tableMessages}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow
              showFilterSelector
              iconComponent={FilterIcon}
              messages={filterMessages}
            />
            <TableColumnVisibility />
            <Toolbar />
            <ColumnChooser />
            {data.length > 0 && <PagingPanel />}
          </Grid>
          {loading && <LoadingOverlay showOverlay={loading} />}
        </div>
      </div>
    </>
  );
};
