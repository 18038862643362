import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Email from '@material-ui/icons/AccountCircle';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import { LOGIN_API_URL } from '../../configurations/configApi.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import CardFooter from '../../components/Card/CardFooter.jsx';
import LoadingOverlay from '../../components/overlays/LoadingOverlay.jsx';
import SnackbarContent from '../../components/Snackbar/SnackbarContent.jsx';
import loginPageStyle from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';

import { useAuth0 } from '@auth0/auth0-react';

const LoginPage = props => {
  const [cardAnimaton, setcardAnimaton] = useState('cardHidden');
  const [message, setmessage] = useState('');
  const [loginUsername, setloginUsername] = useState('');
  const [loginUsernameState, setloginUsernameState] = useState('');
  const [loginPassword, setloginPassword] = useState('');
  const [loginPasswordState, setloginPasswordState] = useState('');
  const [redirectToDashboard, setredirectToDashboard] = useState(
    false
  );
  const [showOverlay, setshowOverlay] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const { classes } = props;

  const { loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    let timeOutFunction = setTimeout(() => {
      setcardAnimaton('');
    }, 700);
    return () => {
      clearTimeout(timeOutFunction);
      timeOutFunction = null;
    };
  }, []);

  const loginClick = () => {
    setshowOverlay(true);
    if (loginUsernameState === '') {
      setloginUsernameState('error');
    }
    if (loginPasswordState === '') {
      setloginPasswordState('error');
    }
    let LoginData = {
      username: loginUsername,
      password: loginPassword
    };
    axios
      .post(LOGIN_API_URL, LoginData)
      .then(response => {
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userName', response.data.username);
        setshowOverlay(false);
        setredirectToDashboard(true);
      })
      .catch(error => {
        if (
          (error.response &&
            error.response.status &&
            (error.response.status === 404 ||
              error.response.status === 500)) ||
          !error.response
        ) {
          window.location = '/error';
        } else {
          setmessage(error.response.data.message);
          setshowOverlay(false);
        }
      });
  };

  const change = (event, type) => {
    setmessage('');
    switch (type) {
      case 'username':
        if (verifyLength(event.target.value, 1)) {
          setloginUsernameState('success');
          setloginUsername(event.target.value);
        } else {
          setloginUsernameState('error');
        }

        break;
      case 'password':
        if (verifyLength(event.target.value, 1)) {
          setloginPasswordState('success');
          setloginPassword(event.target.value);
        } else {
          setloginPasswordState('error');
        }
        break;
      default:
      // todo
    }
  };

  // function that returns true if value is email, false otherwise
  const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleTrigger = () => {
    setshowPassword(!showPassword);
  };

  if (redirectToDashboard) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.container}>
      <form>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            {message && (
              <SnackbarContent
                message={message}
                // close
                color="danger"
              />
            )}
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${
                  classes.textCenter
                }`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  success={loginUsernameState === 'success'}
                  error={loginUsernameState === 'error'}
                  labelText="Username"
                  id="loginUsername"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => change(event, 'username'),
                    type: 'text',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email
                          className={classes.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  success={loginPasswordState === 'success'}
                  error={loginPasswordState === 'error'}
                  labelText="Password"
                  id="loginPassword"
                  className="passWordDiv"
                  formControlProps={{
                    fullWidth: true
                  }}
                  title={showPassword ? 'Hide' : 'Show'}
                  inputProps={{
                    onChange: event => change(event, 'password'),
                    type: showPassword ? 'text' : 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          className={classes.inputAdornmentIcon}
                          onClick={() => handleTrigger()}
                        >
                          {showPassword
                            ? 'lock_open_outline'
                            : 'lock_outline'}
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={() => loginClick()}
                >
                  Let's Go
                </Button>
              </CardFooter>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={() => loginWithRedirect()}
                  className="stockxbtn"
                >
                  Stockx Login
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
      <LoadingOverlay showOverlay={showOverlay} />
    </div>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
