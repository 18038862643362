
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Auth0validation = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    let url = new URL(document.URL);
    let code = url.searchParams.get("code");
    

    if (isLoading) {
      return <div>Loading ...</div>;
    }
  
    return(
        isAuthenticated && (
            <>

             <h1 className="text-center mt-5">{ code ? "UNDER CONSTRUCTION -" : ""} {code}</h1>
             {/* <h1>{user.name}</h1> */}
        </>
        )
    )
}

export default Auth0validation