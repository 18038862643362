import React, { useReducer, useState, useEffect } from "react";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import NumberFormat from "react-number-format";
import {
  PagingState,
  CustomPaging,
  SearchState,
  DataTypeProvider,
  FilteringState,
} from "@devexpress/dx-react-grid";

import {
  Grid,
  Toolbar,
  TableHeaderRow,
  TableColumnVisibility,
  PagingPanel,
  SearchPanel,
  VirtualTable,
  TableFilterRow,
} from "@devexpress/dx-react-grid-bootstrap4";


import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.jsx";

import SweetAlert from "react-bootstrap-sweetalert";


import LoadingOverlay from "../../components/overlays/LoadingOverlay.jsx";
import {
  BRAND_API_URL
} from "../../configurations/configApi.jsx";

let statusEdited = false;
let setEnterKeyForNameEditor = false;
let setEnterKeyForTotalProductEditor = false
let filterFocus;
let activeValue = false;
let inActiveValue = false;
let filterList = [];

const initialState = {
  data: [],
  loading: false,
  totalCount: 0,
  errorMessage: "",
  successMessage: "",
  activeValue : false,
  inActiveValue : false,
};

function reducer(state, { type, payload }) {
  switch (type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: payload.results,
        totalCount: payload.count,
        loading: false
      };
    case "FETCH_ERROR":
      return {
        ...state,
        loading: false
      };
    case "LOADING":
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [columns] = useState([
    { name: "category_name", title: "Category" },
    { name: "name", title: "Name"},
    { name: "total_product", title: "Total Product" },
    { name: "is_active", title: "Active" },
    { name: "scrappy_status", title: "Scrappy Status" },
    { name: "updated_on", title: "Updated On" }
  ]);
  const [categoryColumns] = useState(["category_name"]);
  const [nameColumns] = useState(["name"]);
  const [totalProductColumns] = useState(["total_product"]);
  const [activeColumns] = useState(["is_active"]); 
  const [ScrappyStatusColumns] = useState(["scrappy_status"]); 
  const [updateColumns] = useState(["updated_on"]); 
  const [pageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastQuery, setLastQuery] = useState();

  const [index, setIndex] = useState(0);
  let [searchValue, setSearchValue] = useState(""); 
  const [initialLoad, setInitialLoad] = useState(true);
  const [sweetAlert, setSweetAlert] = useState("");
  const [filters, setFilters] = useState([]);
  const [enterKeyValue, setEnterKeyValue] = useState([]);

  const [nameFilterOperations] = useState([
    "contains",
    "notContains",
    "startsWith",
    "endsWith",
    "equal",
    "in",
    "doesNotIn"
  ]);
  
  const [totalProductFilterOperations] = useState([
    "equal",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual"
  ]);

  const setFilterNameEditor = () => {
    setFilters(enterKeyValue);
    setCurrentPage(0);
    setEnterKeyForNameEditor = false;
    setEnterKeyForTotalProductEditor = false;
  };

  const [filterIndex, setFilterIndex] = useState(0);
  // function to show icons on filter fields
  const FilterIcon = ({ type }) => {
    /* in and not-in filter is not an in-built filter function,
    so need to apply external filter icon */
    if (type === "in") {
      return <span className="d-block oi oi-magnifying-glass" />;
    } else if (type === "doesNotIn") {
      return <span className="d-block oi oi-magnifying-glass cross" />;
    }
    return <TableFilterRow.Icon type={type} />;
  };

  const getRowId = (row) => {
    return row.id;
  };  

  const totalProductEditor = ({ value, onValueChange, column }) => {
    statusEdited = false;
  
    const handleChange = event => {
      filterFocus = event.target.id;
      if (event.target.value === "") {
        setEnterKeyForTotalProductEditor = true;
        onValueChange(event.target.value);
      }
      const { value: targetValue } = event.target;
      onValueChange(targetValue);
    };
    
    //Function to avoid negative values
    const handleKeypress = (e) => {
      const characterCode = e.key;

      if (e.which === 13) {
        setFilters(enterKeyValue);
        setCurrentPage(0);
      }
      if (characterCode === "Backspace") return;

      const characterNumber = Number(characterCode);
      if (characterNumber >= 0 && characterNumber <= 9) {
        if (e.currentTarget.value && e.currentTarget.value.length) {
          return;
        }
      } else {
        e.preventDefault();
      }
    };

    return (
      <input
        className="form-control text-right"
        type="number"
        id={column.name}
        tabIndex="0"
        placeholder="Filter..."
        value={value === undefined ? "" : value}
        min={0}
        onChange={handleChange}
        onKeyPress={e => handleKeypress(e)}
      />
    );
  };

  const NameEditor = ({ value, onValueChange, row, column }) => {
    statusEdited = false;
  
    const handleKey = (event) => {
      if (event.which === 13) {
        setEnterKeyForNameEditor = true;
        onValueChange(value);
      }
    };
  
    const handleChange = (event) => {
      statusEdited = false;
      filterFocus = event.target.id
      if (event.target.value === "") {
        setEnterKeyForNameEditor = true;
        onValueChange(event.target.value);
      }
      const { value: targetValue } = event.target;
      onValueChange(targetValue);
    };

    return (
      <input
        className="form-control text-right"
        type="text"
        id={column.name}
        tabIndex="0"
        placeholder="Filter..."
        value={value === undefined ? "" : value}
        onChange={handleChange}
        onKeyPress={(e) => handleKey(e)}
      />
    );
  };

  const { data, loading, totalCount } = state;

  // to show this message, before api for listing is called
  const tableMessages = {
    noData: "No Data Found"
  };

  // to show this message on searching panel
  const searchPlaceHolder = {
    searchPlaceholder: "Search..."
  };

  const setSearchState = value => {
    setIndex(1);
    searchValue = value;
    if (value === "") {
      setSearchValue(value);
      setCurrentPage(0);
    }
  };

  const handleSearch = () => {
    setSearchValue(searchValue);
    setCurrentPage(0);
  };

  // function for setting current page
  const setNowPage = val => {
    setIndex(index + 1);
    setFilterIndex(filterIndex + 1);
    setCurrentPage(val);
  };

  // if no error resp or status seems 404 or 500, need to redirect to error page
  const checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
    ) {
      return true;
    }
  };

  const toggleStatus=(brand)=>{
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };
    const data = {
      "is_active": !brand.is_active
    }
    axios(BRAND_API_URL + brand.id + '/', {
      method: "patch",
      headers,
      data
    })
    .then(response => {
        fetchData()
    })
    .catch(error => {
      Sentry.captureException("UpdateBrand API " + error);
      dispatch({ type: "FETCH_ERROR" });
      const sweet = (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => setSweetAlert("")}
          confirmBtnText="OK"
        >
          <h4>Brand - Error in update data!</h4>
        </SweetAlert>
      );
      setSweetAlert(sweet);
    });
  }

  const StatusFormatter = ({ value, row }) => {
    
    return value === true ? (
      <button
        title="Click to Stop scrappy"
        onClick={() => toggleStatus(row)}
        className="s-start"
      >
      </button>
    ) : (
      <button
        onClick={() => toggleStatus(row)}
        title="Click to Start Scrappy "
        className="s-stop"
      >
      </button>
    ) 
  };

  const [filteringStateColumnExtensions] = useState([
    { columnName: "category_name", filteringEnabled: false },
    { columnName: "is_active", filteringEnabled: false },
    { columnName: "scrappy_status", filteringEnabled: false },
    { columnName: "updated_on", filteringEnabled: false },
  ]);

  // filter conditions to be shown in filter fields
  const filterMessages = {
    in: "In",
    doesNotIn: "Not In",
  };

  // function to show the status fields editor on each row and on filter fields
  const StatusEditor = ({ onValueChange}) => {

    const handleChange = (event) => {
      statusEdited = true;
      onValueChange(event.target.name);
      if (event.target.value === "Active" && event.target.checked) {
        activeValue = true;
      }
      if (event.target.value === "Active" && !event.target.checked) {
        activeValue = false;
      }
      if (event.target.value === "Inactive" && event.target.checked) {
        inActiveValue = true;
      }
      if (event.target.value === "Inactive" && !event.target.checked) {
        inActiveValue = false;
      }
    };

    return (
      <div className="cu-checkbox">
        <input
          type="checkbox"
          style={{ width: "30px", height: "40px" }}
          id="active"
          name="active"
          value="Active"
          checked={activeValue}
          onChange={handleChange}
          title="Active"
        />
        <label htmlFor="active" title="Active">
          Active
        </label>
        <input
          type="checkbox"
          style={{ width: "30px", height: "40px" }}
          id="inactive"
          name="inactive"
          checked={inActiveValue}
          value="Inactive"
          onChange={handleChange}
          title="Inactive"
        />
        <label htmlFor="inactive" title="Inactive">
          Inactive
        </label>
      </div>
    );
  };

  const getFilters = val => {
    setFilterIndex(1);
    let statusDupList = [];
    if (val.length > 0 && statusEdited) {
      val.forEach(each => {
        if (each.columnName === "is_active") {
          if (filterList.includes(each["value"])) {
            statusDupList = filterList.filter(e => e !== each["value"]);
            filterList = statusDupList;
          } else {
            filterList.push(each["value"]);
          }
        }
        setFilters(val);
        setCurrentPage(0);
      });
    } else {
      setEnterKeyValue(val);
    }
  };

  const StatusTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
  );
  
  const ScrappyStatusFormatter = ({ value, row }) => {
    
    if (value === 'Running') {
      return (
        <Button color="info" size="sm">
          <Icon color="action" className="rotate">cached</Icon> Running
        </Button>
      );
    } 
      
    if (value === 'Completed') {
      return (
        <Button color="success" size="sm">
          <Icon color="action">check_circle</Icon> Completed
        </Button>
      );
    }

    if (value === 'Waiting') {
      return (
        <Button color="rose" size="sm">
          <Icon color="action">timer</Icon> Waiting
        </Button>
      );
    }
    
      
    return (
      <Button color="warning" size="sm">
        <Icon color="disabled">public</Icon> Pending
      </Button>
    );
 
  };

  const getCondition = (name, condition) => {
    let convertedCondition = ''
    if (name === "is_active") {
      return `is_active`;
    } 
    switch (condition) {
      case 'greaterThan':
        convertedCondition = `${name}__gt`
        break;
      case 'greaterThanOrEqual':
        convertedCondition = `${name}__gte`
        break;
      case 'lessThan':
        convertedCondition = `${name}__lt`
        break;
      case 'lessThanOrEqual':
        convertedCondition = `${name}__lte`
        break;
      case 'equal':
        if (name === 'name'){
          convertedCondition = `${name}__iexact`
        } else if(name === 'total_product'){
          convertedCondition = `${name}`
        }
        break;
      case 'contains':
        convertedCondition = `${name}__icontains`
        break;
      case 'notContains':
        convertedCondition = `exclude|${name}__icontains`
        break;
      case 'startsWith':
        convertedCondition = `${name}__istartswith`
        break;
      case 'endsWith':
        convertedCondition = `${name}__iendswith`
        break;
      case 'in':
        convertedCondition = `${name}__in`
        break;
      case 'doesNotIn':
        convertedCondition = `exclude|${name}__in`
        break;
      default:
        convertedCondition = `${name}`
        break;
    }

    return convertedCondition
  };

  const getQueryString = () => {
    // check for search
    let filter = filters.reduce((acc, { columnName, value, operation }) => {
      let filterName = getCondition(columnName, operation);
      let filterObject = {};
      if (operation === "in" || operation === "doesNotIn") {
        var listIt = [];
        var splitValues = value.split(",");
        splitValues.forEach(each => listIt.push(each.trim()));
        filterObject[filterName] = listIt;
      } else if (columnName === "is_active") {
        if (filterList.length === 0 || filterList.length === 2) {
          filterObject = {};
        } else {
          filterObject[filterName] =
            filterList[0] === "active" ? "True" : "False";
        }
      } else {
        filterObject[filterName] = encodeURIComponent(value.trim());
      }
      acc.push(filterObject);
      return acc;
    }, []);

    filter.push({'site': 1})

    let search = searchValue.trim()
    return `${BRAND_API_URL}?search=${search}&page=${currentPage + 1}&limit=${pageSize}&filter=${JSON.stringify(
      filter
    )}`;
  };

  const fetchData = () => {
    const queryString = getQueryString();
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    fetch(queryString, {
        method: "get",
        headers
      })
      .then(response => response.json())
      .then(brands => {
        dispatch({ type: "FETCH_SUCCESS", payload: brands });
      })

      .catch(error => {
        Sentry.captureException("Brand API " + error);
        if (checkFunction(error)) {
          window.location = "/admin/error";
        } else {
          dispatch({ type: "FETCH_ERROR" });
          const sweet = (
            <SweetAlert
              style={{ display: "block", marginTop: "-100px" }}
              title="Error"
              onConfirm={() => setSweetAlert("")}
              confirmBtnText="OK"
            >
              <h4>Brand - Error in fetching data!</h4>
            </SweetAlert>
          );
          setSweetAlert(sweet);
        }
      });
  };

  // for loading the data
  const loadData = () => {
    const queryString = getQueryString();
    setInitialLoad(false);

    if (queryString !== lastQuery && !loading) {
      fetchData();
      setLastQuery(queryString);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      searchValue = "";
      setEnterKeyForNameEditor = false;
      setEnterKeyForTotalProductEditor=false;
      statusEdited=false;
      activeValue = false;
      inActiveValue = false;
      var projections = document.getElementsByTagName("body")[0];
      projections.classList.remove("projectIcon");
      var testElements = document.getElementsByTagName("body")[0];
      testElements.classList.remove("hideAddition");
    }
    loadData();
    if (document.getElementById(filterFocus)) {
      document.getElementById(filterFocus).focus();
    }
  });

  return (
    <>
      {sweetAlert}
      <div className="card products" style={{ position: "relative" }}>
        {totalCount > 0 && (
          <span  className="count goat" title="Total Brand">
            <NumberFormat
              value={totalCount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </span>
        )}

        {setEnterKeyForNameEditor || setEnterKeyForTotalProductEditor
          ? setFilterNameEditor()
          : ""}

        <span className="reload " title="Reload">
          <Icon onClick={() => fetchData()} title="Reload">
            refresh
          </Icon>
        </span>

        <div className="searchIcon">
          <Icon onClick={handleSearch} title="Search">
            search
          </Icon>
        </div>
        
        <Grid rows={data} columns={columns} getRowId={getRowId}>
          <SearchState onValueChange={setSearchState} />
          <FilteringState
            onFiltersChange={getFilters}
            columnExtensions={filteringStateColumnExtensions}
          />
          <DataTypeProvider for={categoryColumns} />

          <DataTypeProvider for={nameColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          
          <StatusTypeProvider 
            for={activeColumns} 
            formatterComponent={StatusFormatter}
            editorComponent={StatusEditor}
          />
          
          <DataTypeProvider for={totalProductColumns}
            availableFilterOperations={totalProductFilterOperations}
            editorComponent={totalProductEditor}
          />

          <DataTypeProvider for={ScrappyStatusColumns} 
            formatterComponent={ScrappyStatusFormatter}
          />

          <DataTypeProvider for={updateColumns} />
          <VirtualTable messages={data.length === 0 && tableMessages} />
         
          <PagingState
            currentPage={currentPage} // show current page, first time it's set to 1
            onCurrentPageChange={setNowPage} // to change current pa  ge
            pageSize={pageSize} // set page limit to show in one page
          />
          <CustomPaging totalCount={totalCount} />
          <TableFilterRow
            showFilterSelector
            iconComponent={FilterIcon}
            messages={filterMessages}
          />
          <TableHeaderRow />
          <TableColumnVisibility />
          <Toolbar />
          <SearchPanel messages={searchPlaceHolder} />
          {data.length > 0 && <PagingPanel />}
        </Grid>
        <LoadingOverlay showOverlay={loading} />
      </div>
    </>
  );
};
