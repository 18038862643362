import React, { Component } from "react";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import SweetAlert from "react-bootstrap-sweetalert";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Icon from "@material-ui/core/Icon";
import { DataTypeProvider, FilteringState,  SelectionState,
  IntegratedSelection, } from "@devexpress/dx-react-grid";
import {
  ColumnChooser,
  Grid,
  TableColumnVisibility,
  TableHeaderRow,
  TableFilterRow,
  Toolbar,
  VirtualTable,
  TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";

import { PROXY_API_URL } from "../../configurations/configApi.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import LoadingOverlay from "../../components/overlays/LoadingOverlay.jsx";

let successValue =false;

let failValue = false;

let allValue = true;

const getRowId = row => {
  return row.id;
};

export default class Proxy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "url", title: "URL" },
        { name: "status", title: "Status" },
        { name: "created_on", title: "Created On" },
        { name: "updated_on", title: "Updated On" }
      ],
      statusColumns: ["status"],
      holdIpAddress: "",
      tableMessages: {
        noData: "No Data Found"
      },
      totalProxyCount: "",
      sweetAlert: "",
      loading: false,
      listOfProxy: [],
      selection: [],
      filteringStateColumnExtensions:
      [{ columnName: "url", filteringEnabled: false },
       {columnName: "created_on", filteringEnabled: false},
       {columnName: "updated_on", filteringEnabled: false}]
    };

    this.handleSubmitProxy = this.handleSubmitProxy.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.fetchProxyData = this.fetchProxyData.bind(this);
  }

  // to show success message when error/success comes
  fetchMessage(value, status, stateCode) {
    this.setState({ loading: false });
    const sweet = stateCode ? (
      <SweetAlert
        showConfirm
        title="Error"
        onConfirm={() => this.hideAlert()}
        confirmBtnText="OK"
        style={{ display: "block", marginTop: "-200px" }}
      >
        <h4>Error due to 404 or 500 response.Please Try again later :(</h4>
        <ErrorOutlineIcon className="errorOutlineIcon" />
      </SweetAlert>
    ) : (
      <SweetAlert
        showConfirm
        onConfirm={() =>
          status ? this.hideAlert("success") : this.hideAlert()
        }
        confirmBtnText="OK"
        style={{ display: "block", marginTop: "-200px" }}
      >
        {status ? (
          <h3>{value.data.message}</h3>
        ) : (
          <h3>{value.response.data.message}</h3>
        )}
        {status ? (
          <CheckOutlinedIcon className="errorOutlineIcon completed" />
        ) : (
          <ErrorOutlineIcon className="errorOutlineIcon" />
        )}
      </SweetAlert>
    );
    this.setState({ sweetAlert: sweet });
  }

  // function to fetch Proxy data
  fetchProxyData() {
    this.setState({ loading: true });
    successValue = failValue = false;
    allValue = true;
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    axios(PROXY_API_URL, {
      methods: "get",
      headers
    })
      .then(response => {
        this.setState({
          listOfProxy: response.data,
          totalProxyCount: response.data.length,
          loading: false
        });
      })
      .catch(error => {
        if (this.checkFunction(error)) {
          this.fetchMessage(error, "", true);
          Sentry.captureException("Proxy GET API " + error);
        } else {
          this.setState({ loading: true });
        }
      });
  }

  componentDidMount() {
    console.log("this.props ", this.props)
    var projections = document.getElementsByTagName("body")[0];
    projections.classList.remove("projectIcon");
    var testElements = document.getElementsByTagName("body")[0];
    testElements.classList.remove("hideAddition");
    this.setState({ loading: true });
    this.fetchProxyData();
  }

  // function to hide alert
  hideAlert = param => {
    param === "success" && this.fetchProxyData();
    this.setState({ holdIpAddress: "", sweetAlert: "" });
  };

  // function to check erro response
  checkFunction(error) {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
    ) {
      return true;
    }
  }

  setSelection = selectedRows => {
    console.log("paa", selectedRows)
    this.setState({ selection: selectedRows })
  };

  handleRemoveProxy() {
    console.log("handleRemoveProxy ", this.state.selection)
    this.setState({ loading: true });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };
    const data = { proxy_ids: this.state.selection };
    axios(`${PROXY_API_URL}?delete=true` , {
      method: "post",
      data,
      headers
    })
      .then(response => {
        console.log("rrresss success ", response)
        this.fetchMessage(response, true, false);
      })
      .catch(error => {
        if (this.checkFunction(error)) {
          this.fetchMessage(error, "", true);
          Sentry.captureException("Proxy Remove POST API " + error);
        } else {
          console.log("eeeeerrr ", error)
          this.fetchMessage(error, false, false);
        }
      });
  };

  // function to submit proxy inputs
  handleSubmitProxy() {
    this.setState({ loading: true });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };
    var listOfIps = [];
    var listOfInputs = [this.state.holdIpAddress]; // to make the inputs in list
    var replacedOne = listOfInputs.map(eachOne => eachOne.replace(/\n/g, ",")); // replace new line with comma
    var splittedIp = replacedOne[0].split(",");
    splittedIp.forEach(each => each !== "" && listOfIps.push(each.trim()));
    const data = { proxy_ips: listOfIps };
    axios(PROXY_API_URL, {
      method: "post",
      data,
      headers
    })
      .then(response => {
        this.fetchMessage(response, true, false);
      })
      .catch(error => {
        if (this.checkFunction(error)) {
          this.fetchMessage(error, "", true);
          Sentry.captureException("Proxy POST API " + error);
        } else {
          this.fetchMessage(error, false, false);
        }
      });
  }

  // function to handle inputs
  handleInput(event) {
    this.setState({ holdIpAddress: event.target.value });
  }

  StatusFormatter = ({ value }) => {
    return value ? (
      <p className="proxySuccess" title="Success">
        <Icon>check_circle</Icon>
      </p>
    ) : (
      <p className="proxyFail" title="Fail">
        <Icon>error</Icon>
      </p>
    );
  };

   StatusEditor = () => {
    const handleStatusChange = event => {
      let url;
      if(event.target.value === "true"){
        successValue= true;
        failValue= allValue =  false;
      }
      if(event.target.value === "false"){
        failValue= true;
         successValue= allValue = false;
      }
      if(event.target.value === "all"){
        allValue= true;
        successValue= failValue= false;
      }
      const headers = { Authorization: `Token ${localStorage.getItem("token")}` };
      this.setState({ loading : true})
      if( event.target.value === "all"){
        url = PROXY_API_URL;
      }
      else{
        url =`${PROXY_API_URL}?filter=${JSON.stringify([{status:event.target.value}])}`
      }
      axios(url, {
        method: "get",
        headers
      })
        .then(response => {
          this.setState({
            listOfProxy: response.data,
            totalProxyCount: response.data.length,
            loading: false
          });
        })
        .catch(error => {
          if (this.checkFunction(error)) {
            this.fetchMessage(error, "", true);
            Sentry.captureException("Proxy POST API " + error);
          } else {
            this.fetchMessage(error, false, false);
          }
        });
    };

    return (
      <div >
        <input
          type="radio"
          style={{ width: "30px", height: "40px" }}
          id="success"
          name="status"
          value="true"
          checked={successValue}
          onChange={handleStatusChange}
        />
        <label for="success" title="Success">
          Success
        </label>

        <input
          type="radio"
          style={{ width: "30px", height: "40px" }}
          id="fail"
          name="status"
          value="false"
          checked={failValue}
          onChange={handleStatusChange}
        />
        <label for="fail" title="Fail">
          Fail
        </label>

        <input
          type="radio"
          style={{ width: "30px", height: "40px" }}
          id="all"
          name="status"
          defaultChecked
          value="all"
          checked={allValue}
          onChange={handleStatusChange}
        />
        <label for="all" title="All">
          All
        </label>
      </div>
    );
  };

  render() {
    const {
      columns,
      sweetAlert,
      loading,
      holdIpAddress,
      totalProxyCount,
      listOfProxy,
      statusColumns,
      tableMessages,
      selection
    } = this.state;

    const getFilters = (params) => {
      console.log("params");
    }

    return (
      <div className="proxy">
        <span className="reload" title="Reload">
          <Icon onClick={() => this.fetchProxyData()} title="Reload">
            refresh
          </Icon>
        </span>
        {sweetAlert && sweetAlert}
        {totalProxyCount > 0 && (
          <span class="proxy count" title="Total Proxy Count">
            <span>{totalProxyCount}</span>
          </span>
        )}

        <form>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">
              <b>Proxy Addresses</b>
            </label>
            <textarea
              class="form-control"
              placeholder="Paste Your Bulk IP Address"
              value={holdIpAddress}
              id="exampleFormControlTextarea1"
              onChange={this.handleInput}
              rows="3"
            />
            <Button
              className="btn btn-primary"
              title="Submit"
              onClick={this.handleSubmitProxy}
            >
              Submit
            </Button>
          </div>
        </form>
        <div className="tableList">
          <Button className="btn btn-primary removeAllProxy" onClick={() => this.handleRemoveProxy()}>Remove All Proxies</Button>
          <Grid rows={listOfProxy} columns={columns} getRowId={getRowId}>
          <FilteringState
              onFiltersChange={getFilters}
              columnExtensions={this.state.filteringStateColumnExtensions}
            />
            <DataTypeProvider
              for={statusColumns}
              editorComponent={this.StatusEditor}
              formatterComponent={this.StatusFormatter}
            />
            <SelectionState
            selection={selection}
            onSelectionChange={this.setSelection}
          />
          <IntegratedSelection />
            <VirtualTable
              messages={listOfProxy.length === 0 && tableMessages}
            />
            <TableHeaderRow />
            <TableSelection showSelectAll />
            <TableFilterRow />
            <TableColumnVisibility />

            <Toolbar />
            <ColumnChooser />
          </Grid>
        </div>
        <LoadingOverlay showOverlay={loading} />
      </div>
    );
  }
}
