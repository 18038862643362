import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Sentry from "@sentry/browser";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CardContent from "@material-ui/core/CardContent/CardContent";

import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "../../components/Card/Card.jsx";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";
import tasksStyle from "../../assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx";

class Tasks extends React.Component {
  state = {
    category: "",
    brand: "",
    scrappy_id: "",
    releaseTime: "",
    year: "",
    gender: "",
    isRunning: "",
    totalCurrentPage: 0,
    celeryId: "",
    createdOn: "",
    updatedOn: "",
    currentPage: "",
    totalPage: "",
    shoeSize: "",
    sweetAlert: "",
    message: "",
    error: false,
  };

  // function to hide alert
  hideAlert = param => {
    param === "success" && this.fetchSchedulerData();
    param === "error" && this.setState({ error: true });
    this.setState({ sweetAlert: "" });
  };

  // function to get error due to 404/500
  checkFunction(error) {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
    ) {
      return true;
    }
  }

  // to show success message when error/success comes
  fetchMessage(value, status, stateCode) {
    this.setState({ loading: false });
    const sweet = stateCode ? (
      <SweetAlert
        showConfirm
        title="Error"
        onConfirm={() => this.hideAlert("error")}
        style={{ display: "block", marginTop: "-200px" }}
      >
        <h4>Error due to 404 or 500 response.Please Try again later :(</h4>
        <ErrorOutlineIcon className="errorOutlineIcon" />
      </SweetAlert>
    ) : (
      <SweetAlert
        showConfirm
        onConfirm={() =>
          status ? this.hideAlert("success") : this.hideAlert()
        }
        confirmBtnText="OK"
        style={{ display: "block", marginTop: "-200px" }}
      >
        {status ? (
          <h3>{value.data.message}</h3>
        ) : (
          <h3>{value.response.data.message}</h3>
        )}
        {status ? (
          <CheckOutlinedIcon className="errorOutlineIcon completed" />
        ) : (
          <ErrorOutlineIcon className="errorOutlineIcon" />
        )}
      </SweetAlert>
    );
    this.setState({ sweetAlert: sweet });
  }

  fetchToggle(scrappy_id) {
    this.setState({ loading: true, isRunning: !this.state.isRunning });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };
    var URL = this.state.isRunning
      ? `${this.props.url}?action=stop&scrapy_type=${this.props.tabWhich}&scrappy_id=${scrappy_id}`
      : `${this.props.url}?action=start&scrapy_type=${this.props.tabWhich}&scrappy_id=${scrappy_id}`;

    axios(URL, {
      method: "post",
      headers
    })
      .then(response => {
        this.setState({ loading: false });
        this.fetchMessage(response, true, false);
      })
      .catch(error => {
        if (this.checkFunction(error)) {
          Sentry.captureException("Scheduler Fetch Toggle API " + error);
          this.setState({ isRunning: !this.state.isRunning });
          this.fetchMessage(error, "", true);
        } else {
          this.setState({ isRunning: !this.state.isRunning });
          this.fetchMessage(error, false, false);
        }
      });
  }

  fetchSchedulerData() {
    this.setState({ loading: true });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };
    const url =
      this.props.tabWhich === "stockx"
        ? `${this.props.url}Stockx`
        : `${this.props.url}Goat`;

    axios
      .get(url, {
        method: "get",
        headers
      })
      .then(schedulerResponse => {
        let results = schedulerResponse.data;
        results
          ? this.setState({
              scrappy_id: results.id,
              category: results.category,
              brand: results.brand,
              celeryId: results.celery_task_id,
              year: results.year,
              gender: results.gender,
              releaseTime: results.release_time,
              isRunning: results.is_running,
              createdOn: results.created_on,
              updatedOn: results.updated_on,
              currentPage: results.current_page,
              totalCurrentPage: results.total_current_page,
              totalPage: results.total_pages,
              shoeSize: results.shoe_size,
              message: "",
              loading: false
            })
          : this.setState({
              loading: false,
              message: "Scrapy is not configured. Please check in Admin Panel"
            });
      })
      .catch(error => {
        Sentry.captureException("Scheduler Fetch API " + error);
        if (this.checkFunction(error)) {
          this.fetchMessage(error, "", true);
        } else {
          this.setState({ loading: true });
        }
      });
  }

  componentDidMount() {
    this.fetchSchedulerData();
  }

  render() {
    const {
      scrappy_id,
      loading,
      category,
      brand,
      year,
      gender,
      releaseTime,
      currentPage,
      totalCurrentPage,
      totalPage,
      shoeSize,
      createdOn,
      updatedOn,
      isRunning,
      sweetAlert,
      message,
      error,
      celeryId
    } = this.state;

    return (
      <>
        <span className="reload " title="Reload">
          <Icon onClick={() => this.fetchSchedulerData()} title="Reload">
            refresh
          </Icon>
        </span>
        {sweetAlert && sweetAlert}
        {!error ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                {message ? (
                  <h2>{message}</h2>
                ) : (
                  <CardContent>
                    <div class="table-responsive table-scheduler">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>Status</td>
                            <td class="text-left">
                              {isRunning === true ? (
                                <button
                                  title="Click here to stop"
                                  onClick={() => this.fetchToggle(scrappy_id)}
                                  className="s-start"
                                >
                                  <label>Running</label>
                                </button>
                              ) : isRunning === false ? (
                                <button
                                  onClick={() => this.fetchToggle(scrappy_id)}
                                  title="Click here to start"
                                  className="s-stop"
                                >
                                  <label>Stopped</label>
                                </button>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Category</td>
                            <td class="text-left">{category}</td>
                          </tr>
                          <tr>
                            <td>Brand</td>
                            <td class="text-left">{brand}</td>
                          </tr>
                          {this.props.tabWhich === "stockx" ? (
                            ""
                          ) : (
                            <tr>
                              <td>Shoe Size</td>
                              <td class="text-left">{shoeSize}</td>
                            </tr>
                          )}
                          <tr>
                            <td>Gender</td>
                            <td class="text-left">{gender}</td>
                          </tr>
                          <tr>
                            <td>Year</td>
                            <td class="text-left">{year}</td>
                          </tr>
                          {this.props.tabWhich === "stockx" ? (
                            <tr>
                              <td>Release Time</td>
                              <td class="text-left">{releaseTime}</td>
                            </tr>
                          ) : (
                            ""
                          )}
                          <tr>
                            <td>Current Page</td>
                            <td class="text-left">{currentPage}</td>
                          </tr>
                          <tr>
                            <td>Current Total Page</td>
                            <td class="text-left">{totalCurrentPage}</td>
                          </tr>
                          <tr>
                            <td>Total Page</td>
                            <td class="text-left">{totalPage}</td>
                          </tr>
                          <tr>
                            <td>Task ID</td>
                            <td class="text-left">{celeryId}</td>
                          </tr>
                          <tr>
                            <td>Created On</td>
                            <td class="text-left">{createdOn}</td>
                          </tr>
                          <tr>
                            <td>Updated On</td>
                            <td class="text-left">{updatedOn}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          <h2>Error! No data to show</h2>
        )}
        <LoadingOverlay showOverlay={loading} />
      </>
    );
  }
}

Tasks.propTypes = {
  url: PropTypes.string,
  tabWhich: PropTypes.string
};

export default withStyles(tasksStyle)(Tasks);
