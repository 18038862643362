import React, { Component } from "react";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";

import Tabs from "../../components/CustomTabs/CustomTabs";
import Tasks from "../../components/Tasks/Tasks";
// core components
import Permission from "../../permission/Permission.jsx";
import { SCHEDULER_API_URL } from "../../configurations/configApi.jsx";

export default class Scheduler extends Component {
  componentDidMount() {
    var projections = document.getElementsByTagName("body")[0];
    projections.classList.remove("projectIcon");
    var testElements = document.getElementsByTagName("body")[0];
    testElements.classList.remove("hideAddition");
  }

  render() {
    return (
      <div className="scheduler">
        <Permission />
        <Tabs
          title="Scrapy Scheduler:"
          headerColor="rose"
          tabs={[
            {
              tabName: "Stockx",
              tabIcon: BugReport,
              tabContent: (
                <Tasks tabWhich="stockx" url={`${SCHEDULER_API_URL}`} />
              )
            },
            {
              tabName: "Goat Products",
              tabIcon: Code,
              tabContent: <Tasks tabWhich="goat" url={`${SCHEDULER_API_URL}`} />
            }
          ]}
        />
      </div>
    );
  }
}
