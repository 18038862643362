import React, { useReducer, useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import NumberFormat from "react-number-format";
import { Tooltip } from "reactstrap";

import {
  ColumnChooser,
  Grid,
  PagingPanel,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  DataTypeProvider,
  PagingState,
  CustomPaging
} from "@devexpress/dx-react-grid";
import Icon from "@material-ui/core/Icon";

import LoadingOverlay from "../../components/overlays/LoadingOverlay.jsx";
import {

  GLOBAL_UPDATE_API
} from "../../configurations/configApi.jsx";
import "react-datepicker/dist/react-datepicker.css";

const initialState = {
  data: [],
  loading: false,
  totalCount: 0,
  errorMessage: "",
  successMessage: "",
  Backbtn:false,
  statusEditor:true,
  paginationStatus:false,
};



function reducer(state, { type, payload }) {
  switch (type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: payload.results,
        totalCount: payload.count,
        loading: false,
        statusEditor:false,
        pageRunId:"",
        Backbtn:true
      };
    case "FETCH_ERROR":
      return {
        ...state,
        loading: false
      };
    case "LOADING":
      return {
        ...state,
        loading: true
      };
    case "RUN_ID":
      return {
        ...state,
        runIds: payload.data
      }
    case "GLOBAL_UPDATE_TABLE_DATA":
      return {
        ...state,
        data: payload.results,
        totalCount: payload.count,
        statusEditor:true,
        Backbtn:false,
        loading: false
      }
    default:
      return state;
  }
}



export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [lastQuery, setLastQuery] = useState();
  const [runIdLastQuery,setrunIdLastQuery]= useState()
  const [initialLoad, setInitialLoad] = useState(true);
  const [sweetAlert, setSweetAlert] = useState("");
  const [statusColumns] = useState(["status"]);
  const [messageColumns] = useState(["status_message"]);
  const [autoBidColumns] = useState(["auto_bid_status"]);
  const [pageSize] = useState(10); // to get the number of rows in each page
  const [currentPage, setCurrentPage] = useState(0);
  const [RunIdcurrentPage, setRunIdcurrentPage] = useState(0); // to get current page
  const [FilterParamColumn] = useState(["filter_params"])
  const [IsActiveColumn] = useState(["is_active"])

 const [columns] = useState([ 
  { name: "created_on", title: "Created On" },
  { name: "status", title: "Status" },
  { name: "global_update_value", title: "Global Update Value" },
  { name: "no_of_products", title: "No Of Products" },
  {name:"filter_params",title:"Filter Params"},
  { name: "is_active", title: "Is Active" },
  { name: "started_on", title: "Started On" },
  { name: "ended_on", title: "Last Update" },],)
 
 const [columnWidths] = useState([
  { columnName: 'created_on', width: 140 },
  { columnName: 'global_update_value', width: 140 },
  { columnName: 'started_on', width: 140 },
  { columnName: 'status', width: 140 },
  { columnName: 'is_active', width: 140 },
  { columnName: 'filter_params', width: 380 },
  { columnName: 'no_of_products', width: 140 },
  { columnName: 'ended_on', width: 150 },
]);
 
 const[paginationStatus,setpaginationStatus]=useState(false)

  const { data, loading, totalCount, Backbtn,statusEditor } = state;


  // to show this message, before api for listing is called
  const tableMessages = {
    noData: "No Data Found"
  };

  const getQueryString = () => {
    return `${GLOBAL_UPDATE_API}?page=${currentPage + 1}`;
  };

  const getGlobalupdateQueryString=()=>{
    return `${GLOBAL_UPDATE_API}?page=${RunIdcurrentPage+1}`;
  }



 
  const fetchGlobalupdateData=()=>{
    const GlobalupdateQueryString = getGlobalupdateQueryString();
    setpaginationStatus(false)

    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

       axios(GlobalupdateQueryString, {
        method: "get",
        headers
      })
        .then(response => {
          dispatch({ type: "GLOBAL_UPDATE_TABLE_DATA", payload: response.data });
        })
        .catch(error => {
          Sentry.captureException("GLOBAL_UPDATE Fetch API " + error);
        });
  }


  // for loading the data
  const loadData = () => {
    const queryString = getQueryString();
    const GlobalupdateQueryString = getGlobalupdateQueryString();
    setInitialLoad(false); 

    if (queryString !== lastQuery && !loading) {
        fetchGlobalupdateData();
      setLastQuery(queryString);
    }else if(GlobalupdateQueryString!== runIdLastQuery && !loading){
        fetchGlobalupdateData();
      setrunIdLastQuery(GlobalupdateQueryString);
    }
  };

  //refresh Data

  const refreshData=()=>{
    setSweetAlert("")
      fetchGlobalupdateData();
  }

  useEffect(() => {
    if (initialLoad) {
      var projections = document.getElementsByTagName("body")[0];
      projections.classList.remove("projectIcon");
      var testElements = document.getElementsByTagName("body")[0];
      testElements.classList.remove("hideAddition");
    }
    loadData();
   
  });


  // to show custom formatting in image field in each expanded tables
  const MessageFormatter = ({ value, row }) => {
    return value === "PLACED" ? (
      <>
        {row["status_message"] !== null ? (
          <p>{row["status_message"]}</p>
        ) : (
            <p>Placed</p>
          )}
      </>
    ) : (
        <>
          {row["status_message"] !== null ? (
            <p>{row["status_message"]}</p>
          ) : (
              <p>Error</p>
            )}
        </>
      );
  };



  // capability to customize formatting
  const MessageTypeProvider = props => (
    <DataTypeProvider formatterComponent={MessageFormatter} {...props} />
  );



  // to show custom formatting in image field in each expanded tables
  const AutoBidFormatter = ({ value }) => {
    return (
      <input
        type="checkbox"
        defaultChecked={value}
        title={value ? "Active" : "Inactive"}
        disabled={true}
      />
    );
  };

  const IsActiveFormatter = ({ value }) => {
    return (
      <input
        type="checkbox"
        defaultChecked={value}
        title={value ? "Active" : "Inactive"}
        disabled={true}
      />
    );
  };

  // capability to customize formatting
  const AutoBidTypeProvider = props => (
    <DataTypeProvider formatterComponent={AutoBidFormatter} {...props} />
  );

 const IsActiveTypeProvider = props => (
  <DataTypeProvider formatterComponent={IsActiveFormatter} {...props} />
);

  // to show custom formatting in image field in each expanded tables
  const StatusFormatter = ({ value, row }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const spanRef = useRef(null);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    if(!statusEditor){
      return value === "PLACED" ? (
        <>
          {row["status_message"] !== null ? (
            <>
              <span ref={spanRef}>
                <p className="proxySuccess">
                  <Icon>check_circle</Icon>
                </p>
              </span>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={spanRef}
                toggle={toggle}
                className="customTooltip"
              >
                {row["status_message"]}
                <br />
              </Tooltip>
            </>
          ) : (
              <p className="proxySuccess" title="Placed">
                <Icon>check_circle</Icon>
              </p>
            )}
        </>
      ) : (
          <>
            {row["status_message"] !== null ? (
              <>
                <span ref={spanRef}>
                  <p className="proxyFail">
                    <Icon>error</Icon>
                  </p>
                </span>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  autohide={false}
                  target={spanRef}
                  toggle={toggle}
                  className="customTooltip"
                >
                  {row["status_message"]}
  
                  <br />
                </Tooltip>
              </>
            ) : (
                <p className="proxyFail" title="Error">
                  <Icon>error</Icon>
                </p>
              )}
          </>
        );
    }else{
     
      return value === "COMPLETED" ? (
            <>
              <span>
                <p className="proxySuccess  text-center">
                <span  ref={spanRef}><Icon>check_circle</Icon></span> 
                </p>
              </span>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={spanRef}
                toggle={toggle}
                className="customTooltip"
              >
                {row["status"]}
                <br />
              </Tooltip>
            </>
         
      ):  value === "UNDOING" ? (
        <>
        <span ref={spanRef}>
          <p className="text-center">
            <Icon>hourglass_empty</Icon>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):value === "IN PROGRESS" ? (
        <>
        <span >
          <p className="proxySuccess  text-center">
           <span ref={spanRef}><Icon>cached</Icon> </span>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):value === "QUEUED" ? (
        <>
        <span>
          <p className="text-center ">
          <span ref={spanRef}> <Icon>dynamic_feed</Icon> </span>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):value === "REVERTED" ? (
        <>
        <span ref={spanRef}>
          <p className="text-center">
            <Icon>keyboard_return</Icon>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):value === "CANCELLED" ? (
        <>
        <span ref={spanRef}>
          <p className="text-center">
            <Icon>cancel</Icon>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):""
    }
   
  };

  // capability to customize formatting
  const StatusTypeProvider = props => (
    <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
  )


 const FilterParamFormatter=({ value,row }) => {
  
   if(row["filter_params"]!==null){
    let filter_by_data = []
     if(row["filter_params"].filter_by === null || row["filter_params"].filter_by===undefined){
      filter_by_data=[]
     }else{
      filter_by_data= row["filter_params"].filter_by
     }

     let data = []
     for(let datakey in row["filter_params"]){
       if(datakey !== "filter_by"&& typeof(row["filter_params"][datakey])==="string"){
         let obj={
          [datakey]:row["filter_params"][datakey]
         }
          data.push(obj)
       }
     }

     return(
    <>
    {   
        filter_by_data.map((filterkey,index)=>{
          for(let filtertemp in filterkey){
            return (
            <p>{index===0?"Filter : " : ""}{filtertemp} : {filterkey[filtertemp]}</p>
            );
            
          }
         })
    }
    {
      data.map((datakey)=>{
        for(let datatemp in datakey){
          return (
            <>
            <p >{datatemp ==="order_by"?"Order":datatemp ==="search_by"?"Search":datatemp} : {datakey[datatemp]}</p>
            </>
          );
        }
       })
    }
    </>
  )
   }else{
     return ""
   }
  };

  const FilterParamTypeProvider = props=>(
    <DataTypeProvider formatterComponent={FilterParamFormatter} {...props} />
  )




  return (
    <div className="globalUpdate">
       {
          Backbtn &&  <button className="bulkUpdate" onClick={()=>{fetchGlobalupdateData()}}>
          Back
          </button>
        }
      <span className="reload" title="Reload"><Icon onClick={() => refreshData()} title="Reload">refresh</Icon></span>
      {sweetAlert}
      <div className="card history">
        {totalCount > 0 && (
          <span className="bulk count" title="Total Bulk Updates">
            <NumberFormat
              value={totalCount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </span>
        )}

        <Grid rows={data} columns={columns} >
          <StatusTypeProvider for={statusColumns} />
          <MessageTypeProvider for={messageColumns} />
          <AutoBidTypeProvider for={autoBidColumns} />
          <IsActiveTypeProvider for={IsActiveColumn} />
          <FilterParamTypeProvider for ={FilterParamColumn}/>
          <PagingState
            currentPage={paginationStatus ? currentPage : RunIdcurrentPage} // show current page, first time it's set to 1
            onCurrentPageChange={paginationStatus ? setCurrentPage: setRunIdcurrentPage} // to change current page
            pageSize={pageSize} // set page limit to show in one page
          />
          <CustomPaging totalCount={totalCount} />
          <VirtualTable messages={data.length === 0 && tableMessages} />
          <TableColumnResizing columnWidths={columnWidths}/>
          <TableHeaderRow />
         
          <TableColumnVisibility />
          <Toolbar />
          <ColumnChooser />
          {data.length > 0 && <PagingPanel />}
        </Grid>
        <LoadingOverlay showOverlay={loading} />
      </div>
    </div>
  );
};
