import React, { useState, useEffect, useReducer, useRef } from "react";
import Parser from "html-react-parser";
import { Tooltip } from "reactstrap";
import * as Sentry from "@sentry/browser";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import {
  CustomGrouping,
  CustomPaging,
  DataTypeProvider,
  FilteringState,
  GroupingState,
  PagingState,
  SearchState,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  ColumnChooser,
  DragDropProvider,
  Grid,
  GroupingPanel,
  PagingPanel,
  SearchPanel,
  TableColumnVisibility,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
  VirtualTable
} from "@devexpress/dx-react-grid-bootstrap4";
import Icon from "@material-ui/core/Icon";

import Permission from "../../permission/Permission";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "../../components/overlays/LoadingOverlay.jsx";
import { GOAT_API_URL } from "../../configurations/configApi";
import "react-datepicker/dist/react-datepicker.css";

const URL = GOAT_API_URL;
let setEnterKeyForNameEditor = false;
let searchValueNew = "";
let statusEdited = false;
let filterList = [];
let setEnterKeyForPurchaseEditor = false;
let selectedDate = "";

const getRowId = row => {
  return row.id;
};

const getChildGroups = groups =>
  groups.map(group => {
    return {
      key: [
        group.name,
        group.original_picture_url,
        group.product_url,
        group.story_html
      ],
      childRows: group.product_variants
    };
  });

const FilterIcon = ({ type }) => {
  if (type === "in") {
    return <span className="d-block oi oi-magnifying-glass" />;
  } else if (type === "doesNotIn") {
    return <span className="d-block oi oi-magnifying-glass cross" />;
  }
  return <TableFilterRow.Icon type={type} />;
};

const filterMessages = {
  in: "In",
  doesNotIn: "Not In"
};

const initialState = {
  data: [],
  loading: false,
  totalCount: 0,
  grouping: [{ columnName: "id" }],
  expandedGroups: [],
  tempGrouping: null,
  tempExpandedGroups: null
};

const tableMessages = {
  noData: "No Data Found"
};

const NameEditor = ({ value, onValueChange, row, column }) => {
  statusEdited = false;

  const handleKeyDown = event => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === "v") {
      setEnterKeyForNameEditor = true;
    } else if (event.ctrlKey && charCode === "x") {
      setEnterKeyForNameEditor = true;
    }
  };

  const handleKey = event => {
    if (event.which === 13) {
      setEnterKeyForNameEditor = true;
      onValueChange(value);
    }
  };

  const handleChange = event => {
    if (event.target.value === "") {
      setEnterKeyForNameEditor = true;
      onValueChange(event.target.value);
    }
    const { value: targetValue } = event.target;
    onValueChange(targetValue);
  };

  return row ? (
    <p>{row[column.name]}</p>
  ) : (
    <input
      className="form-control text-right"
      type={
        column.name === "brand" ||
        column.name === "sku" ||
        column.name === "name"
          ? "text"
          : "number"
      }
      tabIndex="0"
      placeholder="Filter..."
      value={value === undefined ? "" : value}
      min={0}
      onChange={handleChange}
      onKeyPress={e => handleKey(e)}
      onKeyDown={e => handleKeyDown(e)}
    />
  );
};

const DateEditor = ({ value, onValueChange, row, column }) => {
  statusEdited = false;

  const handleKey = e => {
    if (e.which === 13) {
      setEnterKeyForPurchaseEditor = true;
      onValueChange(value);
    } else {
      e.preventDefault();
      return false;
    }
  };

  const handleChange = event => {
    selectedDate = event;
    let month = event && event.getMonth() + 1;
    const formatDate = event
      ? event.getFullYear() + "-" + month + "-" + event.getDate()
      : "";
    setEnterKeyForPurchaseEditor = true;
    onValueChange(formatDate);
  };

  return row ? (
    <p>{value}</p>
  ) : (
    <DatePicker
      className="pick-calender"
      dateFormat="MM/dd/yyyy"
      placeholderText="mm/dd/yyyy"
      selected={selectedDate}
      isClearable
      onChange={date => handleChange(date)}
      onKeyDown={event => handleKey(event)}
    />
  );
};

function reducer(state, { type, payload }) {
  const { grouping, expandedGroups, tempGrouping } = state;

  switch (type) {
    case "CHANGE_GROUPING":
      return {
        ...state,
        loading: true,
        grouping: payload,
        tempGrouping: tempGrouping === null ? grouping : tempGrouping,
        tempExpandedGroups: expandedGroups
      };
    case "SET_EXPANDED_GROUPS":
      return {
        ...state,
        expandedGroups: payload
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: payload.results,
        totalCount: payload.count,
        totalChildCount: payload.variant_count,
        tempExpandedGroups: null,
        loading: false
      };
    case "FETCH_ERROR":
      return {
        ...state,
        loading: false
      };
    case "LOADING":
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [columns] = useState([
    { name: "id", title: "Id" },
    { name: "name", title: "Item" },
    { name: "brand", title: "Brand" },
    { name: "sku", title: "SKU" },
    { name: "size", title: "Size" },
    { name: "lowest_price", title: "Lowest Price" },
    { name: "instant_ship_lowest_price", title: "Instant Ship Price" },
    { name: "last_sale", title: "Last Sale Price" },
    { name: "release_date", title: "Release Date" },
    { name: "shoe_condition", title: "Shoe Condition" },
    { name: "upper_material", title: "Upper Material" },
    { name: "main_color", title: "Color" },
    { name: "colorway", title: "Colorway" },
    { name: "designer", title: "Designer" },
    { name: "silhouette", title: "Silhouette" },
    { name: "technology", title: "Technology" },
    { name: "category", title: "Category" },
    { name: "product_type", title: "Type" },
    { name: "created_on", title: "Created On" },
    { name: "updated_on", title: "Updated On" }
  ]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [lastQuery, setLastQuery] = useState();
  const [sorting, setSorting] = useState([]); // sorting param
  const [sortIndex, setSortIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const [searchValue, setSearchValue] = useState(""); // to get search params
  const [nameColumns] = useState(["name"]);
  const [idColumns] = useState(["id"]);
  const [skuColumns] = useState(["sku"]);
  const [shoeColumns] = useState(["shoe_condition"]);
  const [brandColumns] = useState(["brand"]);
  const [sizeColumns] = useState(["size"]);
  const [releaseDateColumns] = useState(["release_date"]);
  const [lowestPriceColumns] = useState(["lowest_price"]);
  const [instantPriceColumns] = useState(["instant_ship_lowest_price"]);
  const [lastSalePriceColumns] = useState(["last_sale"]);
  const [filters, setFilters] = useState([]);
  const [filterIndex, setFilterIndex] = useState(0);
  const [pageSize] = useState(30); // to get the number of rows in each page
  const [currentPage, setCurrentPage] = useState(0); // to get current page
  const [enterKeyValue, setEnterKeyValue] = useState([]);
  const [sweetAlert, setSweetAlert] = useState("");

  const [groupingStateColumnExtensions] = useState([
    { columnName: "name", groupingEnabled: false },
    { columnName: "brand", groupingEnabled: false },
    { columnName: "sku", groupingEnabled: false },
    { columnName: "category", groupingEnabled: false },
    { columnName: "product_type", groupingEnabled: false },
    { columnName: "main_color", groupingEnabled: false },
    { columnName: "size", groupingEnabled: false },
    { columnName: "shoe_condition", groupingEnabled: false },
    { columnName: "created_on", groupingEnabled: false },
    { columnName: "updated_on", groupingEnabled: false },
    { columnName: "lowest_price", groupingEnabled: false },
    { columnName: "instant_ship_lowest_price", groupingEnabled: false },
    { columnName: "last_sale", groupingEnabled: false },
    { columnName: "upper_material", groupingEnabled: false },
    { columnName: "release_date", groupingEnabled: false },
    { columnName: "colorway", groupingEnabled: false },
    { columnName: "designer", groupingEnabled: false },
    { columnName: "silhouette", groupingEnabled: false },
    { columnName: "technology", groupingEnabled: false }
  ]);
  const [filteringStateColumnExtensions] = useState([
    { columnName: "category", filteringEnabled: false },
    { columnName: "product_type", filteringEnabled: false },
    { columnName: "main_color", filteringEnabled: false },
    { columnName: "created_on", filteringEnabled: false },
    { columnName: "updated_on", filteringEnabled: false },
    { columnName: "shoe_condition", filteringEnabled: false },
    { columnName: "upper_material", filteringEnabled: false },
    { columnName: "colorway", filteringEnabled: false },
    { columnName: "designer", filteringEnabled: false },
    { columnName: "silhouette", filteringEnabled: false },
    { columnName: "technology", filteringEnabled: false }
    // { columnName: "release_date", filteringEnabled: false }
  ]);
  const [sortingStateColumnExtensions] = useState([
    { columnName: "sku", sortingEnabled: false },
    { columnName: "shoe_condition", sortingEnabled: false },
    { columnName: "category", sortingEnabled: false },
    { columnName: "product_type", sortingEnabled: false },
    { columnName: "main_color", sortingEnabled: false },
    { columnName: "upper_material", sortingEnabled: false },
    { columnName: "colorway", sortingEnabled: false },
    { columnName: "designer", sortingEnabled: false },
    { columnName: "silhouette", sortingEnabled: false },
    { columnName: "technology", sortingEnabled: false }
    // { columnName: "size", sortingEnabled: false }
  ]);
  const [numberFilterOperations] = useState([
    "equal",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual"
  ]);
  const [nameFilterOperations] = useState([
    "contains",
    "notContains",
    "startsWith",
    "endsWith",
    "equal",
    "in",
    "doesNotIn"
  ]);
  const [dateFilterOperations] = useState([
    "equal",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual"
  ]);

  const {
    data,
    loading,
    totalCount,
    totalChildCount,
    expandedGroups,
    tempGrouping,
    tempExpandedGroups,
    grouping
  } = state;

  const changeGrouping = value => {
    dispatch({ type: "CHANGE_GROUPING", payload: value });
  };

  const setExpandedGroups = value => {
    dispatch({ type: "SET_EXPANDED_GROUPS", payload: value });
  };

  const getCondition = (name, condition) => {
    if (name === "shoe_condition") {
      return `product_variants__shoe_condition__icontains`;
    } else if (condition === "contains") {
      return name === "brand" ? "brand_name__icontains" : `${name}__icontains`;
    } else if (condition === "notContains") {
      return name === "brand"
        ? "exclude|brand_name__icontains"
        : `exclude|${name}__icontains`;
    } else if (condition === "startsWith") {
      return name === "brand"
        ? "brand_name__istartswith"
        : `${name}__istartswith`;
    } else if (condition === "endsWith") {
      return name === "brand" ? "brand_name__iendswith" : `${name}__iendswith`;
    } else if (
      ["sku", "name", "brand"].includes(name) &&
      condition === "equal"
    ) {
      return name === "brand" ? "brand_name__iexact" : `${name}__iexact`;
    } else if (condition === "in") {
      return name === "brand" ? "brand_name__in" : `${name}__in`;
    } else if (condition === "doesNotIn") {
      return name === "brand"
        ? "exclude|brand_name__in"
        : `exclude|${name}__in`;
    } else if (condition === "greaterThan") {
      return name === "release_date"
        ? "release_date__date__gt"
        : `product_variants__${name}__gt`;
    } else if (condition === "greaterThanOrEqual") {
      return name === "release_date"
        ? "release_date__date__gte"
        : `product_variants__${name}__gte`;
    } else if (condition === "lessThan") {
      return name === "release_date"
        ? "release_date__date__lt"
        : `product_variants__${name}__lt`;
    } else if (condition === "lessThanOrEqual") {
      return name === "release_date"
        ? "release_date__date__lte"
        : `product_variants__${name}__lte`;
    }else if (condition === "equal" && name === "release_date" ) {
      return "release_date__date";
    } else {
      return `product_variants__${name}`;
    }
  };

  const getQueryString = () => {
    let sortingStr;

    let queryString = `${URL}`;

    let filter = filters.reduce((acc, { columnName, value, operation }) => {
      let filterName = getCondition(columnName, operation);
      let filterObject = {};

      if (operation === "in" || operation === "doesNotIn") {
        var listIt = [];
        var splitValues = value.split(",");
        splitValues.forEach(each => listIt.push(each.trim()));
        filterObject[filterName] = listIt;
      } else if (columnName === "shoe_condition") {
        if (filterList.length === 0 || filterList.length === 2) {
          filterObject = {};
        } else {
          filterObject[filterName] = filterList[0] === "new" ? "new" : "used";
        }
      } else {
        filterObject[filterName] = encodeURIComponent(value.trim());
      }
      acc.push(filterObject);
      return acc;
    }, []);

    // check for sorting
    if (sorting.length) {
      const sortingConfig = sorting.map(({ columnName, direction }) => {
        // let colName = columnName === "brand" ? "brand_name" : columnName;
        let colName =
          columnName === "brand"
            ? "brand_name"
            : columnName === "size"
            ? "product_variants__size"
            : columnName === "lowest_price"
            ? "product_variants__lowest_price"
            : columnName === "instant_ship_lowest_price"
            ? "product_variants__instant_ship_lowest_price"
            : columnName;
        return direction === "desc" ? `-${colName}` : colName;
      });
      sortingStr = JSON.stringify(sortingConfig);
    } else {
      sortingStr = JSON.stringify([""]);
    }

    // check for search
    let search = columns.reduce(() => {
      return searchValue;
    }, []);

    if (columns.length > 1) {
      search = `[${JSON.stringify(search.trim())}]`;
    }

    return `${queryString}?page=${currentPage + 1}&filter=${JSON.stringify(
      filter
    )}&order_by=${sortingStr}&search=${search}`;
  };

  const fetchData = () => {
    const queryString = getQueryString();
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    fetch(queryString, {
      method: "get",
      headers
    })
      .then(response => response.json())
      .then(data => {
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      })
      .catch(error => {
        Sentry.captureException("Goat Products Fetch API " + error);
        dispatch({ type: "FETCH_ERROR" });
        const sweet = (
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Error"
            onConfirm={() => setSweetAlert("")}
            confirmBtnText="OK"
          >
            <h4>Goat Products Tab - Error in fetching data!</h4>
          </SweetAlert>
        );
        setSweetAlert(sweet);
      });
  };

  // for loading the data
  const loadData = () => {
    const queryString = getQueryString();
    setInitialLoad(false);

    if (queryString !== lastQuery && !loading) {
      fetchData();
      setLastQuery(queryString);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      setEnterKeyForNameEditor = false;
      searchValueNew = "";
      statusEdited = false;
      filterList = [];
      setEnterKeyForPurchaseEditor = false;
      selectedDate = "";
      var projections = document.getElementsByTagName("body")[0];
      projections.classList.remove("projectIcon");
      var testElements = document.getElementsByTagName("body")[0];
      testElements.classList.remove("hideAddition");
    }
    loadData();
  });

  // Format camel case in shoe condition
  const camelize = str => {
    // Split the string at all space characters
    return str
      ? str
          .split("_")
          // get rid of any extra spaces using trim
          .map(a => a.trim())
          // Convert first char to upper case for each word
          .map(a => a[0].toUpperCase() + a.substring(1))
          // Join all the strings back together
          .join(" ")
      : "";
  };

  const ShoeFormatter = ({ value }) => {
    return <p>{camelize(value)}</p>;
  };

  // formatting purchase date
  const DateFormatter = ({ value }) => {
    return value === "None" || value === "" ? "" : <p>{value}</p>;
  };

  const NameFormatter = ({ value, row }) => (
    <a href={row["url"]} target="_blank" rel="noopener noreferrer">
      <p>
        {value}, Size - {row.size}
      </p>
    </a>
  );

  const IdFormatter = ({ value }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const spanRef = useRef(null);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    let detailValue = value[3] ? JSON.stringify(value[3].trim("/n")) : "";
    let parsedDetailValue = detailValue && Parser(detailValue);

    return (
      <>
        <a href={value[2]} target="_blank" rel="noopener noreferrer">
          <img src={value[1]} alt="" />
        </a>
        <span ref={spanRef}>{value[0]}</span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {parsedDetailValue ? parsedDetailValue : "No Detail"}
          <br />
        </Tooltip>
      </>
    );
  };

  // to show custom formating last sale field
  const lastSaleFormatter = ({ value , row}) => {
    const last_sale_history = row['last_sale_history']
    return (
      <>
        { last_sale_history &&
          (last_sale_history.map((item, index)=>{
            return (<span>{index + 1} - {item['number_of_sale'] && (item['number_of_sale'])} <strong className="proxySuccess mr-2">{item['last_sale']} </strong> {item['updated_on']}<br/></span>)
          }))
        }
      </>
    );
  };

  const setFilterNameEditor = () => {
    setFilters(enterKeyValue);
    setCurrentPage(0);
    setEnterKeyForNameEditor = false;
    setEnterKeyForPurchaseEditor = false;
  };

  const getFilters = val => {
    setFilterIndex(1);
    let statusDupList = [];
    if (val.length > 0 && statusEdited) {
      val.forEach(each => {
        if (each.columnName === "shoe_condition") {
          if (filterList.includes(each["value"])) {
            statusDupList = filterList.filter(e => e !== each["value"]);
            filterList = statusDupList;
          } else {
            filterList.push(each["value"]);
          }
        }
        setFilters(val);
        setCurrentPage(0);
      });
    } else {
      setEnterKeyValue(val);
    }
  };

  // function for setting current page
  const setNowPage = val => {
    setIndex(index + 1);
    setFilterIndex(filterIndex + 1);
    sortIndex === 1 && setSortIndex(sortIndex + 1);
    setCurrentPage(val);
  };

  // sort function to override the setSorting method
  const setSort = val => {
    setSortIndex(1);
    setSorting(val);
    setCurrentPage(0);
  };

  const setSearchState = value => {
    setIndex(1);
    searchValueNew = value;
    if (value === "") {
      setSearchValue(value);
      setCurrentPage(0);
    }
  };

  const handleSearch = () => {
    setSearchValue(searchValueNew);
    setCurrentPage(0);
  };

  return (
    <>
      {sweetAlert}
      <div className="goatpro">
        <Permission />
        <span className="reload " title="Reload">
          <Icon onClick={() => fetchData()} title="Reload">
            refresh
          </Icon>
        </span>
        {setEnterKeyForNameEditor || setEnterKeyForPurchaseEditor
          ? setFilterNameEditor()
          : ""}
        <div className="card" style={{ position: "relative" }}>
          {totalChildCount > 0 && (
            <span title="Total Count" className="count goat">
              <NumberFormat
                value={totalChildCount}
                displayType={"text"}
                thousandSeparator={true}
              />
            </span>
          )}
          <div className="searchIcon">
            <Icon onClick={handleSearch} title="Search">
              search
            </Icon>
          </div>

          <Grid rows={data} columns={columns} getRowId={getRowId}>
            <FilteringState
              onFiltersChange={getFilters}
              columnExtensions={filteringStateColumnExtensions}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={setSort}
              columnExtensions={sortingStateColumnExtensions}
            />
            <SearchState onValueChange={setSearchState} />
            <DataTypeProvider
              for={skuColumns}
              editorComponent={NameEditor}
              availableFilterOperations={nameFilterOperations}
            />
            <DataTypeProvider
              for={brandColumns}
              editorComponent={NameEditor}
              availableFilterOperations={nameFilterOperations}
            />
            <DataTypeProvider
              for={idColumns}
              formatterComponent={IdFormatter}
            />
            <DataTypeProvider
              for={nameColumns}
              formatterComponent={NameFormatter}
              editorComponent={NameEditor}
              availableFilterOperations={nameFilterOperations}
            />
            <DataTypeProvider
              for={sizeColumns}
              editorComponent={NameEditor}
              availableFilterOperations={numberFilterOperations}
            />
            <DataTypeProvider
              for={lowestPriceColumns}
              editorComponent={NameEditor}
              availableFilterOperations={numberFilterOperations}
            />
            <DataTypeProvider
              for={instantPriceColumns}
              editorComponent={NameEditor}
              availableFilterOperations={numberFilterOperations}
            />
            <DataTypeProvider
              for={lastSalePriceColumns}
              editorComponent={NameEditor}
              formatterComponent={lastSaleFormatter}
              availableFilterOperations={numberFilterOperations}
            />
            <DataTypeProvider
              for={shoeColumns}
              formatterComponent={ShoeFormatter}
            />
            <DataTypeProvider
              for={releaseDateColumns}
              editorComponent={DateEditor}
              formatterComponent={DateFormatter}
              availableFilterOperations={dateFilterOperations}
            />
            <PagingState
              currentPage={currentPage} // show current page, first time it's set to 1
              onCurrentPageChange={setNowPage} // to change current page
              pageSize={pageSize} // set page limit to show in one page
            />
            <CustomPaging totalCount={totalCount} />
            <DragDropProvider />
            <GroupingState
              grouping={grouping}
              columnExtensions={groupingStateColumnExtensions} // to control grouping with some fields
              onGroupingChange={changeGrouping}
              expandedGroups={expandedGroups}
              onExpandedGroupsChange={setExpandedGroups}
            />
            <CustomGrouping
              getChildGroups={getChildGroups}
              grouping={tempGrouping}
              expandedGroups={tempExpandedGroups}
            />

            <VirtualTable messages={data.length === 0 && tableMessages} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow
              showFilterSelector
              iconComponent={FilterIcon}
              messages={filterMessages}
            />
            <TableColumnVisibility />
            <TableGroupRow />
            <Toolbar />
            <ColumnChooser />
            <GroupingPanel />
            <SearchPanel />
            {data.length > 0 && <PagingPanel />}
          </Grid>
          {loading && <LoadingOverlay showOverlay={loading} />}
        </div>
      </div>
    </>
  );
};
