import React, { useReducer, useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import NumberFormat from 'react-number-format';
import SweetAlert from 'react-bootstrap-sweetalert';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Icon from '@material-ui/core/Icon';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';

import axios from 'axios';
import {
  GroupingState,
  CustomGrouping,
  SortingState,
  PagingState,
  CustomPaging,
  SearchState,
  DataTypeProvider,
  EditingState,
  FilteringState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableInlineCellEditing,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  PagingPanel,
  SearchPanel,
  ColumnChooser,
  TableColumnVisibility,
  TableEditRow,
  TableEditColumn,
  TableFilterRow
} from '@devexpress/dx-react-grid-bootstrap4';
import Cancel from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';

import {
  PRODUCT_API_URL,
  BUY_UPDATE_API_URL,
  HIDE_API_URL,
  PRODUCT_SYNC_API_URL
} from '../../configurations/configApi.jsx';
import LoadingOverlay from '../../components/overlays/LoadingOverlay.jsx';
import Permission from '../../permission/Permission.jsx';
import GridItem from '../../components/Grid/GridItem';

import SnackbarContent from '../../components/Snackbar/SnackbarContent.jsx';
import spinner from '../../assets/img/spinner.svg';

import { Alert } from 'react-bootstrap';

const URL = PRODUCT_API_URL;

let fakeList = [];

let statusEdited = false;

let retainErrorListValue = [];

let setEnterKeyForNameEditor = false;

let setEnterKeyForPurchaseEditor = false;

let filterFocus, rowFocus;

let lenOfBids = 0;

let searchValueNew = '';

let hideProducts = false;

let bulkHide = '';

let bulkUpdate = false;

let bulk = true;

let BidEditorProductFilterStatus = false;

let errors = [];

let errorResponseData = {};

let bidUpdateStatus = [];
let bulkUpdateFilterOperation = '';

const getRowId = row => {
  return row.id;
};

const getChildGroups = groups =>
  groups.map(group => {
    return {
      key: [
        group.key,
        group.items[0]['image_url'],
        group.items[0]['url'],
        group.items[0]['parent_id'],
        group.items[0]['is_hidden']
      ],
      childRows: group.items
    };
  });

const FilterIcon = ({ type }) => {
  if (type === 'month') {
    return <span className="d-block oi oi-calendar" />;
  } else if (type === 'in') {
    return <span className="d-block oi oi-magnifying-glass" />;
  } else if (
    ['doesNotIn', 'dynamicStatusIsActive', 'emptyValue'].includes(type)
  ) {
    return <span className="d-block oi oi-magnifying-glass cross" />;
  }
  return <TableFilterRow.Icon type={type} />;
};

const tableMessages = {
  noData: 'No Data Found'
};

const filterMessages = {
  in: 'In',
  doesNotIn: 'Not In',
  emptyValue: 'No Value',
  noSaleInPast: 'No sales in past by day',
};

const NameEditor = ({ value, onValueChange, row, column }) => {
  const handleKey = event => {
    if (event.which === 13) {
      setEnterKeyForNameEditor = true;
      onValueChange(value);
    }
  };

  const handleKeyDown = event => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === 'v') {
      setEnterKeyForNameEditor = true;
    } else if (event.ctrlKey && charCode === 'x') {
      setEnterKeyForNameEditor = true;
    }
  };

  const handleChange = event => {
    filterFocus = '';
    rowFocus = '';
    statusEdited = false;
    if (event.target.value === '') {
      setEnterKeyForNameEditor = true;
      onValueChange(event.target.value);
    }
    const { value: targetValue } = event.target;
    onValueChange(targetValue);
  };
  if (row) {
    return <p>{row[column.name]}</p>;
  } else {
    if (
      column.name === 'created_on' ||
      column.name === 'updated_on'
    ) {
      return '';
    } else {
      return (
        <input
          className="form-control text-right"
          type="text"
          tabIndex="0"
          placeholder="Filter..."
          value={value === undefined ? '' : value}
          min={0}
          onChange={handleChange}
          onKeyPress={e => handleKey(e)}
          onKeyDown={e => handleKeyDown(e)}
        />
      );
    }
  }
};

const initialState = {
  data: [],
  grouping: [{ columnName: 'title' }],
  expandedGroups: [],
  tempGrouping: null,
  tempExpandedGroups: null,
  loading: false,
  totalCount: 0,
  totalInactive: 0,
  errorMessage: '',
  successMessage: ''
};

function reducer(state, { type, payload }) {
  const { grouping, expandedGroups, tempGrouping } = state;

  switch (type) {
    case 'CHANGE_GROUPING':
      return {
        ...state,
        loading: true,
        grouping: payload,
        tempGrouping:
          tempGrouping === null ? grouping : tempGrouping,
        tempExpandedGroups: expandedGroups
      };
    case 'SET_EXPANDED_GROUPS':
      return {
        ...state,
        expandedGroups: payload
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        data: payload.data,
        totalCount: payload.totalCount,
        totalInactive: payload.total_inactive,
        tempGrouping: null,
        tempExpandedGroups: null,
        loading: false
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false
      };
    case 'FETCH_COMMIT_CHANGES':
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      };
    case 'FETCH_CHANGE_SUCCESS':
      return {
        ...state,
        loading: false,
        successMessage: payload.message
      };
    case 'FETCH_UPDATE_ERROR':
      return {
        ...state,
        errorMessage: payload.message,
        loading: false
      };
    case 'LOADING':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [defaultHiddenColumnNames] = useState(['id', 'parent_id']);
  const [columns] = useState([
    { name: 'name', title: 'Item' },
    { name: 'title', title: 'Title' },
    { name: 'sale_last72_hours', title: 'Sale Last 72 Hours' },
    { name: 'brand', title: 'Brand' },
    { name: 'size', title: 'Size' },
    { name: 'bid_amount', title: 'Bid Price' },
    { name: 'lowest_ask', title: 'Lowest Ask' },
    { name: 'highest_bid', title: 'Highest Bid' },
    { name: 'last_sale', title: 'Last Sale' },
    { name: 'category', title: 'Category' },
    { name: 'color', title: 'Colour' },
    { name: 'style_id', title: 'Style Id' },
    { name: 'number_of_bids', title: 'No: of Bids' },
    { name: 'number_Of_asks', title: 'No: of Asks' },
    { name: 'created_on', title: 'Created On' },
    { name: 'updated_on', title: 'Updated On' },
    { name: 'department', title: 'Department' },
    { name: 'retail_price', title: 'Retail Price' },
    { name: 'release_year', title: 'Release Year' },
    { name: 'id', title: 'Id' },
    { name: 'parent_id', title: 'Parent Id' }
  ]); //column to specify in table
  const [sorting, setSorting] = useState([
    { columnName: 'created_on', direction: 'desc' }
  ]); // sorting param
  const [lastQuery, setLastQuery] = useState(); // used to check and invoke loadData()
  const [pageSize] = useState(30); // to get the number of rows in each page
  const [currentPage, setCurrentPage] = useState(0); // to get current page
  const [searchValue, setSearchValue] = useState(''); // to get search params
  const [flag, setFlag] = useState([]);
  const [imageColumns] = useState(['title']); // to display the custom field value with image
  const [filters, setFilters] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]); //404 errors
  const [show, setShow] = useState(false); // to show alert message for delete if 404 or 500 error.

  const [groupingStateColumnExtensions] = useState([
    { columnName: 'name', groupingEnabled: false },
    { columnName: 'bid_amount', groupingEnabled: false },
    { columnName: 'brand', groupingEnabled: false },
    { columnName: 'lowest_ask', groupingEnabled: false },
    { columnName: 'highest_bid', groupingEnabled: false },
    { columnName: 'last_sale', groupingEnabled: false },
    { columnName: 'category', groupingEnabled: false },
    { columnName: 'color', groupingEnabled: false },
    { columnName: 'size', groupingEnabled: false },
    { columnName: 'url', groupingEnabled: false },
    { columnName: 'image_url', groupingEnabled: false },
    { columnName: 'title', groupingEnabled: false },
    { columnName: 'style_id', groupingEnabled: false },
    { columnName: 'number_of_bids', groupingEnabled: false },
    { columnName: 'number_Of_asks', groupingEnabled: false },
    { columnName: 'created_on', groupingEnabled: false },
    { columnName: 'updated_on', groupingEnabled: false },
    { columnName: 'department', groupingEnabled: false },
    { columnName: 'id', groupingEnabled: false },
    { columnName: 'parent_id', groupingEnabled: false },
    { columnName: 'release_year', groupingEnabled: false },
    { columnName: 'retail_price', groupingEnabled: false },
    { columnName: 'sale_last72_hours', groupingEnabled: false }
  ]); // to disable grouping with this boolean field
  const [editStateColumnExtensions] = useState([
    { columnName: 'name', editingEnabled: false },
    { columnName: 'bid_amount', editingEnabled: true },
    { columnName: 'brand', editingEnabled: false },
    { columnName: 'lowest_ask', editingEnabled: false },
    { columnName: 'highest_bid', editingEnabled: false },
    { columnName: 'category', editingEnabled: false },
    { columnName: 'color', editingEnabled: false },
    { columnName: 'size', editingEnabled: false },
    { columnName: 'url', editingEnabled: false },
    { columnName: 'style_id', editingEnabled: false },
    { columnName: 'number_of_bids', editingEnabled: false },
    { columnName: 'number_Of_asks', editingEnabled: false },
    { columnName: 'title', editingEnabled: false },
    { columnName: 'created_on', editingEnabled: false },
    { columnName: 'updated_on', editingEnabled: false },
    { columnName: 'department', editingEnabled: false },
    { columnName: 'id', editingEnabled: false },
    { columnName: 'parent_id', editingEnabled: false },
    { columnName: 'last_sale', editingEnabled: false },
  ]); // to disable grouping with this boolean field
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'bid_amount', sortingEnabled: false },
    { columnName: 'image_url', sortingEnabled: false },
    { columnName: 'url', sortingEnabled: false },
    { columnName: 'id', sortingEnabled: false },
    { columnName: 'parent_id', sortingEnabled: false }
  ]);
  const [filteringStateColumnExtensions] = useState([
    { columnName: 'bid_amount', filteringEnabled: false },
    { columnName: 'created_on', filteringEnabled: false },
    { columnName: 'updated_on', filteringEnabled: false }
  ]);
  const editColumnMessages = {
    editCommand: <Edit title="Edit" />,
    deleteCommand: <Delete title="Delete" />,
    commitCommand: <Save title="Save" />,
    cancelCommand: <Cancel title="Cancel" />
  };
  const [idColumns] = useState(['id']);
  const [bidColumns] = useState(['bid_amount']);
  const [sweetAlert, setSweetAlert] = useState('');
  const [lowestAskColumns] = useState(['lowest_ask']);
  const [numberOfBidsColumns] = useState(['number_of_bids']);
  const [numberOfAsksColumns] = useState(['number_Of_asks']);
  const [highestBidColumns] = useState(['highest_bid']);
  const [createdOnColumns] = useState(['created_on']);
  const [nameColumns] = useState(['name']);
  const [salesLast72HoursColumns] = useState(['sale_last72_hours']);
  const [brandColumns] = useState(['brand']);
  const [categoryColumns] = useState(['category']);
  const [colorColumns] = useState(['color']);
  const [sizeColumns] = useState(['size']);
  const [styleColumns] = useState(['style_id']);
  const [departmentColumns] = useState(['department']);
  const [parentIdColumns] = useState(['parent_id']);
  const [updatedOnColumns] = useState(['updated_on']);
  const [releaseYearColumns] = useState(['release_year']);
  const [retailPriceColumns] = useState(['retail_price']);
  const [lastSaleColumns] = useState(['last_sale']);

  const [index, setIndex] = useState(0);
  const [sortIndex, setSortIndex] = useState(0);
  // const [doneList, setDoneList] = useState([]);
  const [mainDoneList, setMainDoneList] = useState([]);
  // const [failList, setFailList] = useState([]);
  const [mainfailList, setMainFailList] = useState([]);
  const [spin, setSpin] = useState(false);
  const [forceSpin, setForceSpin] = useState(false);
  const [btn, setBtn] = useState(false);
  const [listOfBids, setListOfBids] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [bulkData, setBulkData] = useState({ page_type: 'current' }); // to store the bulk update datas
  const [bidFilterOperations] = useState([
    'equal',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
    'emptyValue',
  ]);

  const [lastSaleFilterOperations] = useState([
    'equal',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
    'noSaleInPast',
    'emptyValue'
  ]);

  const [nameFilterOperations] = useState([
    'contains',
    'notContains',
    'startsWith',
    'endsWith',
    'equal',
    'in',
    'doesNotIn',
    'emptyValue'
  ]);
  const [filterIndex, setFilterIndex] = useState(0);
  const [enterKeyValue, setEnterKeyValue] = useState([]);

  const setFilterNameEditor = () => {
    setFilters(enterKeyValue);
    setCurrentPage(0);
    setEnterKeyForNameEditor = false;
    setEnterKeyForPurchaseEditor = false;
  };

  // for changing the groups
  const changeGrouping = value => {
    dispatch({ type: 'CHANGE_GROUPING', payload: value });
  };

  // for expanding each grouped data
  const setExpandedGroups = value => {
    dispatch({ type: 'SET_EXPANDED_GROUPS', payload: value });
    if (document.getElementById(filterFocus)) {
      filterFocus = '';
    }
    if (document.getElementById(rowFocus)) {
      rowFocus = '';
    }
  };

  // sort function to override the setSorting method
  const setSort = val => {
    setSortIndex(1);
    if (flag.length) {
      let dupVal = val;
      // if val gets multiple columnNames, we need to splice and remove to latest selected column
      if (val.length > 1) {
        val.forEach((e, i) => {
          if (JSON.stringify(e) === JSON.stringify(flag[0])) {
            dupVal.splice(i, 1);
            setSorting(dupVal);
            setCurrentPage(0);
            setFlag(dupVal);
          }
        });
      } else {
        setFlag(val);
        setSorting(val);
        setCurrentPage(0);
      }
    } else {
      setFlag(val);
      setSorting(val);
      setCurrentPage(0);
    }
  };

  const setSearchState = value => {
    setIndex(1);
    searchValueNew = value;
    if (value === '') {
      setSearchValue(value);
      setCurrentPage(0);
    }
  };

  const handleSearch = () => {
    bulk = true;
    setSearchValue(searchValueNew);
    setCurrentPage(0);
  };

  const BidEditor = ({ value, onValueChange, row, column }) => {
    var data = {};

    const handleChange = event => {
      rowFocus = event.target.id;
      if (event.target.value === '') {
        BidEditorProductFilterStatus = true;
      }
      retainErrorListValue = [];
      statusEdited = false;
      setMainDoneList([]);
      setMainFailList([]);
      if (row) {
        rowFocus = event.target.id;
        setBtn(true);
        if (listOfBids.length > 0) {
          // we maintain all changed row id in fakeList, and if this row.id exists in fakeList
          // then we filter that row and make the needed changes
          if (fakeList.includes(row.id)) {
            var index = fakeList.indexOf(row.id);
            var copy = listOfBids.filter(
              each => each.bid_id === row.id
            );
            let e1 = copy[0];
            e1[event.target.name] = event.target.value;
            listOfBids.splice(index, 1);
            fakeList.splice(index, 1);
            fakeList.push(row.id);
            listOfBids.push(e1);
            setListOfBids(listOfBids);
          } else {
            data['bid_id'] = row.id;
            data[event.target.name] = event.target.value;
            if (Object.keys(data).length > 0) {
              listOfBids.push(data);
              fakeList.push(row.id);
              setListOfBids(listOfBids);
            }
          }
        } else {
          data['bid_id'] = row.id;
          data[event.target.name] = event.target.value;
          if (Object.keys(data).length > 0) {
            listOfBids.push(data);
            fakeList.push(row.id);
            setListOfBids(listOfBids);
          }
        }
      } else {
        filterFocus = event.target.id;
      }
      const { value: targetValue } = event.target;
      onValueChange(targetValue);
    };

    //Function to avoid negative values
    const handleKeypress = e => {
      const characterCode = e.key;
      if (e.which === 13) {
        setFilters(enterKeyValue);
        setCurrentPage(0);
      }
      if (characterCode === 'Backspace') return;

      const characterNumber = Number(characterCode);
      if (characterNumber >= 0 && characterNumber <= 9) {
        if (e.currentTarget.value && e.currentTarget.value.length) {
          return;
        }
      } else {
        e.preventDefault();
      }
    };

    if (row) {
      if (column['name'] === 'bid_amount') {
        const error = retainErrorListValue.filter(
          each => each.id === row.id
        );
        return (
          <input
            className="form-control text-right"
            value={error[0] ? '' : value}
            name={column['name']}
            autoFocus={row.id === rowFocus}
            type="number"
            tabIndex="0"
            id={row.id}
            min={0}
            onChange={handleChange}
            onKeyPress={e => handleKeypress(e)} //avoid negative values
          />
        );
      } else {
        return <p>{row[column.name]}</p>;
      }
    } else {
      if (
        column['name'] === 'bid_amount' ||
        column.name === 'updated_on' ||
        column.name === 'id'
      ) {
        return '';
      } else {
        return (
          <input
            className="form-control text-right"
            type="number"
            placeholder="Filter..."
            id={column['name']}
            value={value === undefined ? '' : value}
            min={0}
            tabIndex="0"
            onChange={handleChange}
            onKeyPress={e => handleKeypress(e)} //avoid negative values
          />
        );
      }
    }
  };

  const getCondition = (name, condition) => {
    let queryCondition = '';
    let queryField = '';
    switch (name) {
      case 'is_modeling_auto_bid_active':
        queryField = 'modeling_max_bid_amount';
        break;
      case 'status_image':
        queryField = 'active';
        break;
      case 'is_auto_bid_active':
        queryField = 'is_auto_bid_active';
        break;
      case 'retail_price':
        queryField = 'parent_product__retail_price';
        break;
      case 'release_year':
        queryField = 'parent_product__year';
        break;
      case 'auto_bid_last_sync_modified':
        queryField = 'auto_bid_last_sync__date';
        break;
      case 'size':
        queryField = 'size';
        break;
      case 'name':
        queryField = 'parent_product__title';
        break;
      case 'department':
        queryField = 'parent_product__product_category';
        break;
      case 'parent_id':
        queryField = 'parent_product__id';
        break;
      case 'chainId':
        queryField = 'chain_id';
        break;
      case 'brand':
        queryField = 'parent_product__brand';
        break;
      case 'category':
        queryField = 'parent_product__category';
        break;
      case 'color':
        queryField = 'parent_product__color';
        break;
      case 'sale_last72_hours':
        queryField = 'parent_product__sale_last72_hours';
        break;
      case 'bid_amount':
      case 'max_bid_amount':
      case 'lowest_ask':
      case 'highest_bid':
      case 'last_sale':
      case 'number_Of_asks':
      case 'number_of_bids':
        queryField = `${name}`;
        break;
      default:
        queryField = `parent_product__${name}`;
        break;
    }

    const fieldWithoutCondition = [
      'status_image',
      'is_auto_bid_active',
      'active'
    ];

    if (fieldWithoutCondition.includes(queryField)) {
      bulkUpdateFilterOperation = '-';
      return queryField;
    }

    switch (condition) {
      case 'greaterThan':
        bulkUpdateFilterOperation = 'Greater than';
        queryCondition = `${queryField}__gt`;
        break;
      case 'greaterThanOrEqual':
        bulkUpdateFilterOperation = 'Greater than or equal to';
        queryCondition = `${queryField}__gte`;
        break;
      case 'lessThan':
        bulkUpdateFilterOperation = 'Less than';
        queryCondition = `${queryField}__lt`;
        break;
      case 'lessThanOrEqual':
        bulkUpdateFilterOperation = 'Less than or equal to';
        queryCondition = `${queryField}__lte`;
        break;
      case 'contains':
        bulkUpdateFilterOperation = 'Contains';
        queryCondition = `${queryField}__icontains`;
        break;
      case 'notContains':
        bulkUpdateFilterOperation = 'Does not Contains';
        queryCondition = `exclude|${queryField}__icontains`;
        break;
      case 'startsWith':
        bulkUpdateFilterOperation = 'Starts with';
        queryCondition = `${queryField}__istartswith`;
        break;
      case 'endsWith':
        bulkUpdateFilterOperation = 'Ends with';
        queryCondition = `${queryField}__iendswith`;
        break;
      case 'equal':
        bulkUpdateFilterOperation = 'Equals';
        queryCondition = `${queryField}__iexact`;
        break;
      case 'in':
        bulkUpdateFilterOperation = 'In';
        queryCondition = `${queryField}__in`;
        break;
      case 'doesNotIn':
        bulkUpdateFilterOperation = 'Not in';
        queryCondition = `exclude|${queryField}__in`;
        break;
      case 'month':
        bulkUpdateFilterOperation = 'Month';
        queryCondition = `${queryField}__month`;
        break;
      case 'greaterThanOrEqualMaxBid':
        bulkUpdateFilterOperation =
          'Greater than or equal max bid amount';
        queryCondition = `modeling_max_bid_amount_gt_max_bid_amount`;
        break;
      case 'dynamicStatusIsActive':
        bulkUpdateFilterOperation =
          'Greater than or equal max bid amount';
        queryCondition = `is_modeling_auto_bid_active`;
        break;
      case 'dynamicDisabledAndGreaterThan':
        bulkUpdateFilterOperation = 'Greater than or equal';
        queryCondition = `is_modeling_auto_bid_disabled`;
        break;
      case 'emptyValue':
        bulkUpdateFilterOperation = 'No Value';
        queryCondition = `${queryField}__isnull`;
        break;
      default:
        bulkUpdateFilterOperation = '-';
        queryCondition = `${queryField}__${condition}`;
        break;
    }

    return queryCondition;
  };
  // for retrieving the url of each page
  const getQueryString = () => {
    let sortingStr, groupConfig;

    // check for filter
    let filter = filters.reduce(
      (acc, { columnName, value, operation }) => {
        let filterName = getCondition(columnName, operation);
        let filterObject = {};
        if (columnName === 'status_image') {
          filterObject[filterName] = value ? 1 : 0;
        } else {
          if (operation === 'in' || operation === 'doesNotIn') {
            var listIt = [];
            var splitValues = value.split(',');
            splitValues.forEach(each => listIt.push(each.trim()));
            filterObject[filterName] = listIt;
          } else {
            filterObject[filterName] = encodeURIComponent(
              value.trim()
            );
          }
        }
        acc.push(filterObject);
        return acc;
      },
      []
    );

    // check for search
    let search = columns.reduce(() => {
      return searchValue;
    }, []);

    bulkHide =
      bulk === true && (filter.length > 0 || search !== '')
        ? false
        : '';

    if (columns.length > 1) {
      search = `[${JSON.stringify(search.trim())}]`;
    }

    // check for sorting
    if (sorting.length) {
      const sortingConfig = sorting.map(
        ({ columnName, direction }) => {
          let colName =
            columnName === 'name'
              ? 'title'
              : columnName === 'department'
                ? 'product_category'
                : columnName === 'release_year'
                  ? 'year'
                  : columnName;
          return direction === 'desc' ? `-${colName}` : colName;
        }
      );
      sortingStr = JSON.stringify(sortingConfig);
    } else {
      sortingStr = JSON.stringify(['-created_on']);
    }

    // check for grouping
    if (grouping.length) {
      groupConfig = grouping.map(columnGrouping => ({
        selector: columnGrouping.columnName,
        isExpanded: true
      }));
    } else {
      groupConfig = [];
    }

    bulkUpdate =
      filter.length > 0 || JSON.parse(search)[0] !== ''
        ? true
        : false;

    return hideProducts
      ? `${URL}?group=${JSON.stringify(
        groupConfig
      )}&order_by=${sortingStr}&search=${search}&filter=${JSON.stringify(
        filter
      )}&page=${currentPage +
      1}&limit=${pageSize}&show_hidden=True`
      : `${URL}?group=${JSON.stringify(
        groupConfig
      )}&order_by=${sortingStr}&search=${search}&filter=${JSON.stringify(
        filter
      )}&page=${currentPage +
      1}&limit=${pageSize}&enable_buying=False`;
  };

  const checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  const fetchData = () => {
    const queryString = getQueryString();
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };
    dispatch({ type: 'LOADING' });

    fetch(queryString, {
      method: 'get',
      headers
    })
      .then(response => response.json())
      .then(orders => {
        dispatch({ type: 'FETCH_SUCCESS', payload: orders });
      })
      .catch(error => {
        Sentry.captureException('Products Fetch API ' + error);
        dispatch({ type: 'FETCH_ERROR' });
        const sweet = (
          <SweetAlert
            style={{ display: 'block', marginTop: '-100px' }}
            title="Error"
            onConfirm={() => hideAlert()}
            confirmBtnText="OK"
          >
            <h4>Error in fetching data!</h4>
          </SweetAlert>
        );
        setSweetAlert(sweet);
      });
  };

  // for loading the data
  const loadData = () => {
    const queryString = getQueryString();
    setInitialLoad(false);

    if (queryString !== lastQuery && !loading) {
      fetchData();
      setLastQuery(queryString);
    }
  };

  const handleHideProducts = (parentId, hideStatus, hideState) => {
    dispatch({ type: 'LOADING' });
    bulkHide = hideState ? true : bulkHide;
    bulk = false;
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };
    const data = hideState
      ? ''
      : {
        parent_product: parentId,
        hide: JSON.stringify(hideStatus)
      };
    const query = getQueryString();
    const hideOrBulkUrl = hideState
      ? `${query}&bulk_hidden=True`
      : HIDE_API_URL;
    axios(hideOrBulkUrl, {
      method: hideState ? 'get' : 'post',
      headers,
      data
    })
      .then(response => {
        bulkHide = hideState ? true : '';
        fetchHideSuccessMessage(response, '');
      })
      .catch(error => {
        bulkHide = hideState ? true : '';
        fetchErrorMessage(error, '', 'delete');
      });
  };

  useEffect(() => {
    if (initialLoad) {
      bulkUpdate = false;
      fakeList = [];
      statusEdited = false;
      retainErrorListValue = [];
      setEnterKeyForNameEditor = false;
      lenOfBids = 0;
      searchValueNew = '';
      hideProducts = false;
      bulkHide = '';
      setEnterKeyForPurchaseEditor = false;
      var projections = document.getElementsByTagName('body')[0];
      projections.classList.remove('projectIcon');
    }
    loadData();
    if (document.getElementById(filterFocus)) {
      document.getElementById(filterFocus).focus();
    }
    if (document.getElementById(rowFocus)) {
      document.getElementById(rowFocus).focus();
    }
  });

  // function to post api for getting sync of particular product
  const fetchSyncData = data => {
    dispatch({ type: 'LOADING' });
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };

    axios(PRODUCT_SYNC_API_URL, {
      method: 'post',
      data,
      headers
    })
      .then(response => {
        fetchHideSuccessMessage(response, 'reload');
      })
      .catch(error => {
        Sentry.captureException(
          'Products Page Sync Data API posting ' + error
        );
        if (checkFunction(error)) {
          // alert("Products tab - Sync Products API " + error);
          errors = [];
          errors.push({
            id: data.parent_product,
            msg: 'Product tab - Sync Products API ' + error.message
          });
          fetchHideErrorMessage(errors, 'errorResponse');
        } else {
          fetchHideErrorMessage(error, 'sync');
        }
      });
  };

  const [copiedVal, setCopiedVal] = useState('');
  const [buttonText, setButtonText] = useState('');

  const copytext = titleVal => {
    copy(titleVal);
    setCopiedVal(titleVal);
    setButtonText('Copied !');
  };

  // to show custom formatting in image field in each expanded tables
  const ImageFormatter = ({ value }) => {
    let data = { parent_product: value[3] };

    return (
      <>
        <a
          href={value[2]}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'black' }}
        >
          <img src={value[1]} alt="" />
        </a>
        <span>{value[0]}</span>

        <Icon
          onClick={() => fetchSyncData(data)}
          className="synsBtn"
          title="Sync"
        >
          sync
        </Icon>
        <span
          class="material-icons visibleBtn"
          title={value[4] ? 'Unhide' : 'Hide'}
          onClick={() =>
            handleHideProducts(value[3], !value[4], false)
          }
        >
          {value[4] ? 'visibility_off' : 'visibility'}
        </span>
        <i className="clipboard" title="Copy">
          {value[0] === copiedVal && buttonText ? (
            <span>{buttonText}</span>
          ) : (
            <FileCopyIcon onClick={() => copytext(value[0])} />
          )}
        </i>
      </>
    );
  };

  // to show custom formating last sale field
  const lastSaleFormatter = ({ value, row }) => {
    const last_sale_history = row['last_sale_history'];
    return (
      <p>
        {last_sale_history.map((item, index) => {
          return (
            <span>
              {index + 1} -{' '}
              {item['number_of_sale'] && item['number_of_sale']}{' '}
              <strong className="proxySuccess mr-2">
                {item['last_sale']}{' '}
              </strong>{' '}
              {item['updated_on']}
              <br />
            </span>
          );
        })}
      </p>
    );
  };

  const LastSaleTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={lastSaleFormatter}
      availableFilterOperations={lastSaleFilterOperations}
      editorComponent={BidEditor}
      {...props}
    />
  );

  // capability to customize formatting
  const ImageTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={ImageFormatter}
      {...props}
    />
  );

  const hideAlert = alertStatus => {
    setSweetAlert('');
    mainDoneList.length > 0 && fetchData();
    setMainDoneList([]);
    setMainFailList([]);
    setErrorMessages([]);
    bidUpdateStatus = [];
    retainErrorListValue = mainfailList;
    alertStatus === 'success' && fetchData();
  };

  const messageToShow = (value, forceStatus, status, errorData) => {
    // var dupList = [];
    dispatch({ type: 'FETCH_ERROR' });
    if (status === 'otherResponse') {
      var label = value.response.data.label
        ? value.response.data.label + ' : '
        : '';

      if (forceStatus) {
        mainfailList.forEach(each => {
          if (each.id === value.response.data.id) {
            each['label'] = label + value.response.data.message;
            each['showForce'] = value.response.data.show_force_update
              ? value.response.data.show_force_update
              : '';
            each['id'] = value.response.data.id;
            each['bidAmount'] = value.response.data.bid_amount
              ? value.response.data.bid_amount
              : '';
            each['otherMessages'] = value.response.data
              .other_messages
              ? value.response.data.other_messages
              : '';
          }
        });
        setForceSpin(false);
        setSpin(false);
      } else {
        //FOR DOING MODAL
        var dataToShowForceUpdate = {};
        dataToShowForceUpdate['label'] =
          label + value.response.data.message;
        dataToShowForceUpdate['showForce'] =
          value.response.data.show_force_update;
        dataToShowForceUpdate['id'] = value.response.data.id;
        dataToShowForceUpdate['bidAmount'] =
          value.response.data.bid_amount;
        dataToShowForceUpdate['newBidAmount'] =
          value.response.data.new_bid_amount;
        dataToShowForceUpdate['maxBidAmount'] =
          value.response.data.max_bid_amount;
        dataToShowForceUpdate['otherMessages'] = value.response.data
          .other_messages
          ? value.response.data.other_messages
          : '';
        mainfailList.push(dataToShowForceUpdate);
      }
    }
    if (status === 'update404Response') {
      bidUpdateStatus.push({ errorData });
      setErrorMessages(bidUpdateStatus);
      setSpin(false);
      setForceSpin(false);
    }
    if (lenOfBids === mainfailList.length + mainDoneList.length) {
      setSpin(false);
    }
    // setFailList(dupList);
    setBtn(false);
  };

  const sweetMessage = (
    <SweetAlert
      style={{ display: 'block', marginTop: '-200px' }}
      title={'Bid Updation Status'}
      showCancel={false}
      showConfirm={false}
    >
      {spin && (
        <span className="spinner-box">
          <span>
            <img src={spinner} alt="Loading" />
            Please wait! Bulk updating is on process
          </span>
        </span>
      )}
      {forceSpin && (
        <span className="spinner-box">
          <span>
            <img src={spinner} alt="Loading" />
            Please wait! Force updating is on process
          </span>
        </span>
      )}
      <div className={forceSpin ? 'sa-hide' : 'sa-show'}>
        {mainfailList.length > 0 &&
          mainfailList.map(e => (
            <>
              <SnackbarContent
                key={e.label}
                message={e.label + e.otherMessages}
                color="danger"
                className="warning-box"
              />
              <ErrorIcon />
              {e.showForce && (
                <button
                  onClick={() =>
                    fetchBidUpdate(
                      {
                        bid_id: e.id,
                        bid_amount: e.newBidAmount,
                        force_update: '1'
                      },
                      'forceUpdate'
                    )
                  }
                >
                  Force Update
                </button>
              )}
            </>
          ))}
        {mainDoneList.length > 0 &&
          mainDoneList.map(e => (
            <>
              <SnackbarContent key={e} message={e} color="success" />
              <DoneIcon className="tick-green" />
            </>
          ))}
        {errorMessages.length > 0 &&
          errorMessages.map(item => (
            <>
              <p>
                Product: Error on bid update, after 404 or 500
                response{' '}
              </p>
              <p>
                Product({item.errorData.bidId}) found with error{' '}
              </p>
              <p>{item.errorData.msg}</p>
            </>
          ))}
        <p className="cancelBtn">
          {!spin && !forceSpin && (
            <button type="button" onClick={() => hideAlert()}>
              OK
            </button>
          )}
        </p>
      </div>
    </SweetAlert>
  );

  // to show success message in edit/delete
  const fetchSuccessMessage = (response, forceStatus) => {
    dispatch({ type: 'FETCH_ERROR' });
    var label = response.data.label
      ? response.data.label + ' : '
      : '';
    if (forceStatus) {
      var listofFails = mainfailList.filter(
        each => each.id !== response.data.id
      );
      // setFailList(listofFails);
      setMainFailList(listofFails);
      setSpin(false);
      setForceSpin(false);
    }
    mainDoneList.push(label + response.data.message);
    if (lenOfBids === mainfailList.length + mainDoneList.length) {
      setSpin(false);
    }
    // setDoneList(dupDoneList);
    setBtn(false);
  };

  // to show error message in sweet alert for delete process
  const fetchHideErrorMessage = (value, label) => {
    dispatch({ type: 'FETCH_ERROR' });
    const sweet = (
      <SweetAlert
        showConfirm
        showCancel={false}
        onConfirm={() => hideAlert('error')}
        style={{ display: 'block', marginTop: '-100px' }}
      >
        {label === 'sync' && <h4>{value.response.data.label}</h4>}
        <h2>{value.response ? value.response.data.message : ''}</h2>
        <br />
        <ErrorOutlineIcon className="errorOutlineIcon" />
        {label === 'errorResponse'
          ? value.length > 0 &&
          value.map(item => (
            <>
              <p> {item.msg}</p>
              <p>Product({item.id}) found with error </p>
            </>
          ))
          : ''}
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  // to show error message in edit/delete
  const fetchErrorMessage = (error, forceStatus, process, data) => {
    Sentry.captureException('Products Bid Updation ' + error);
    if (checkFunction(error)) {
      if (process === 'delete') {
        dispatch({ type: 'FETCH_ERROR' });
        setShow(true);
      } else {
        errorResponseData = {
          bidId: data.bid_id,
          msg: error.message
        };
        messageToShow(
          '',
          forceStatus,
          'update404Response',
          errorResponseData
        );
      }
      // window.location = "/admin/error";
    } else {
      process === 'update'
        ? messageToShow(error, forceStatus, 'otherResponse', '')
        : fetchHideErrorMessage(error, '');
    }
  };

  // function to update the bid amount
  const fetchBidUpdate = (data, forceStatus) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };
    forceStatus && setForceSpin(true);

    axios(BUY_UPDATE_API_URL, {
      data,
      headers,
      method: 'post'
    })
      .then(response => {
        fetchSuccessMessage(response, forceStatus);
      })
      .catch(error => {
        fetchErrorMessage(error, forceStatus, 'update', data);
      });
  };

  // to show success message in edit/delete
  const fetchHideSuccessMessage = (response, label) => {
    dispatch({ type: 'FETCH_ERROR' });
    const sweet = (
      <SweetAlert
        showConfirm
        onConfirm={() => hideAlert('success')}
        confirmBtnText="OK"
        style={{ display: 'block', marginTop: '-200px' }}
      >
        {label === 'reload' && <h4>{response.data.label}</h4>}
        <h3>{response.data.message}</h3>
        <CheckOutlinedIcon className="errorOutlineIcon completed" />
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  // function for setting current page
  const setNowPage = val => {
    setFilterIndex(filterIndex + 1);
    setIndex(index + 1);
    sortIndex === 1 && setSortIndex(sortIndex + 1);
    setCurrentPage(val);
  };

  const {
    data,
    expandedGroups,
    tempGrouping,
    tempExpandedGroups,
    grouping,
    loading,
    totalCount,
    totalInactive
  } = state;

  const getFilters = val => {
    bulk = true;
    setFilterIndex(1);
    if (val.length > 0 && statusEdited) {
      val.forEach(each => {
        if (each.columnName === 'status_image') {
          setFilters(val);
          setCurrentPage(0);
        }
      });
    } else {
      if (BidEditorProductFilterStatus === true) {
        setFilters(val);
        setCurrentPage(0);
        BidEditorProductFilterStatus = false;
      }
      setEnterKeyValue(val);
    }
  };

  // function to invoke each bid amount for update
  const fetchNewBulk = () => {
    setSpin(true);
    lenOfBids = listOfBids.length;
    listOfBids.forEach((each, index) => {
      if (index + 1 === listOfBids.length) {
        fakeList = [];
        setListOfBids([]);
      }
      fetchBidUpdate(each, false);
    });
  };

  const fetchHidden = () => {
    hideProducts = !hideProducts;
    let testElements;
    if (hideProducts) {
      testElements = document.getElementsByTagName('body')[0];
      testElements.className += 'hideAddition';
    } else {
      testElements = document.getElementsByTagName('body')[0];
      testElements.classList.remove('hideAddition');
    }
    fetchData();
  };

  const fetchBulkUpdateMessage = (value, status) => {
    dispatch({ type: 'FETCH_ERROR' });
    const sweet = (
      <SweetAlert
        showConfirm
        showCancel={false}
        onConfirm={() => hideAlert()}
        style={{ display: 'block', marginTop: '-100px' }}
      >
        {status ? (
          <h4>{value.data.message}</h4>
        ) : (
          <h4>{value.response.data.message}</h4>
        )}
        {status ? (
          <CheckOutlinedIcon className="errorOutlineIcon completed" />
        ) : (
          <ErrorOutlineIcon className="errorOutlineIcon" />
        )}
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  const handleInputChange = event => {
    // remove h6 tab, so that it will hide the text from user
    var child = document.getElementsByTagName('h6')[0];
    child.classList.remove('h6ClassToShow');

    let name = event.target.name;
    if (event.target.value) {
      bulkData[name] = event.target.value;
    } else {
      delete bulkData[name];
    }
    setBulkData(bulkData);
  };

  const handleBulkUpdateAction = () => {
    if (Object.entries(bulkData).length > 1) {
      dispatch({ type: 'LOADING' });
      setSweetAlert('');
      let bulkStringified = JSON.stringify(bulkData);
      const queryString = getQueryString();
      const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`
      };

      axios
        .get(
          `${queryString}&bulk_update=True&bulk_update_data=[${bulkStringified}]`,
          {
            method: 'get',
            headers
          }
        )
        .then(response => {
          fetchBulkUpdateMessage(response, true);
        })
        .catch(error => {
          fetchBulkUpdateMessage(error, false);
        });
    } else {
      var para = document.getElementsByTagName('h6')[0];
      para.className += ' h6ClassToShow';
    }
  };

  // Function to handle negative values
  const handleNegative = e => {
    const characterCode = e.key;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  const fetchBulkUpdate = () => {
    const sweet = (
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title="Bulk Update"
        onConfirm={() => handleBulkUpdateAction()}
        onCancel={() => hideAlert()}
        confirmBtnText="Submit"
      >
        <GridItem xs={12} sm={12} md={12} id="gridSweetId">
          <h6>
            Please provide values for Bid Amount, Max Bid or Auto Bid
          </h6>
          <div className="row">
            <GridItem xs={12} sm={12} md={12}>
              <table className="col-md-12">
                <tr>
                  <td>Bid Amount</td>
                  <td>
                    <input
                      type="number"
                      id="float"
                      onKeyPress={e => handleNegative(e)}
                      name="auto_bid"
                      min={0}
                      onChange={event => handleInputChange(event)}
                      style={{ width: '50%' }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Max Bid Amount</td>
                  <td>
                    {' '}
                    <input
                      type="number"
                      id="float"
                      onKeyPress={e => handleNegative(e)}
                      name="max_bid"
                      min={0}
                      onChange={event => handleInputChange(event)}
                      style={{ width: '50%' }}
                    />{' '}
                  </td>
                </tr>
                <tr>
                  <td>Auto Bid</td>
                  <td>
                    {' '}
                    <div className="cu-checkbox text-left">
                      <input
                        type="radio"
                        style={{ width: '20px', height: '15px' }}
                        id="activeBid"
                        name="auto_bid_status"
                        value="True"
                        onChange={handleInputChange}
                        title="Active"
                      />
                      <label for="active" title="Active">
                        Active
                      </label>
                      <input
                        type="radio"
                        style={{
                          width: '20px',
                          height: '15px',
                          marginLeft: '20px'
                        }}
                        id="inactiveBid"
                        name="auto_bid_status"
                        value="False"
                        onChange={handleInputChange}
                        title="Inactive"
                      />
                      <label for="inactive" title="Inactive">
                        Inactive
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Apply For</td>
                  <td>
                    {' '}
                    <div className="cu-checkbox text-left">
                      <input
                        type="radio"
                        style={{ width: '20px', height: '15px' }}
                        id="currentPageCheck"
                        defaultChecked
                        name="page_type"
                        value="current"
                        onChange={handleInputChange}
                        title="Current"
                      />
                      <label for="currentPageCheck" title="Current">
                        Current
                      </label>
                      <input
                        type="radio"
                        style={{
                          width: '20px',
                          height: '15px',
                          marginLeft: '20px'
                        }}
                        id="allPageCheck"
                        name="page_type"
                        value="all"
                        onChange={handleInputChange}
                        title="All"
                      />
                      <label for="allPageCheck" title="All">
                        All
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </GridItem>
          </div>
        </GridItem>
      </SweetAlert>
    );
    setSweetAlert(sweet);
  };

  return (
    <>
      {show ? (
        <Alert
          variant="danger"
          onClose={() => setShow(false)}
          dismissible
        >
          <Alert.Heading>
            <Icon>error_outline</Icon>You got an error!
          </Alert.Heading>
          <p>
            Product: Error on hide/unhide, after 404 or 500 response
          </p>
        </Alert>
      ) : (
        ''
      )}

      <div
        className="card products"
        style={{ position: 'relative' }}
      >
        {bulkUpdate && data.length > 0 && !loading && (
          <button className="bulkUpdate" onClick={fetchBulkUpdate}>
            Bulk Update
          </button>
        )}
        {bulkUpdate && data.length > 0 && !loading && !hideProducts && (
          <span className="bulkHide" title="Bulk Hide">
            <Icon onClick={() => handleHideProducts('', '', true)}>
              {'visibility_off'}
            </Icon>
            <Icon className="bulkeye">visibility</Icon>
          </span>
        )}
        {/* {(bulkHide === false || bulkHide === true) && (
          <span className="bulkHide" title="Bulk Hide">
            <Icon onClick={() => handleHideProducts("", "", true)}>
              {bulkHide === true ? "visibility_off" : "visibility"}
            </Icon>
            <Icon className="bulkeye">visibility</Icon>
          </span>
        )} */}
        {totalInactive > 0 && (
          <span className="products count" title="Total Products">
            <NumberFormat
              value={totalInactive}
              displayType={'text'}
              thousandSeparator={true}
            />
          </span>
        )}
        <span className="reload" title="Reload">
          <Icon
            color="secondary"
            onClick={() => fetchData()}
            title="Reload"
          >
            refresh
          </Icon>
        </span>
        <div className="searchIcon">
          <Icon onClick={handleSearch} title="Search">
            search
          </Icon>
        </div>

        {setEnterKeyForNameEditor || setEnterKeyForPurchaseEditor
          ? setFilterNameEditor()
          : ''}
        {mainDoneList.length > 0 ||
          mainfailList.length > 0 ||
          spin ||
          errorMessages.length > 0
          ? sweetMessage
          : ''}
        {sweetAlert}
        <Permission />
        <span
          class={
            hideProducts
              ? 'material-icons all-visibleBtn new-all-visibleBtn'
              : 'material-icons all-visibleBtn'
          }
          onClick={() => fetchHidden()}
          title={
            hideProducts
              ? 'Show All Products'
              : 'Show Hidden Products'
          }
        >
          {hideProducts ? 'visibility' : 'visibility_off'}
        </span>
        <Grid
          rows={data}
          columns={columns}
          getRowId={getRowId}
          className="group"
        >
          {btn && !spin && (
            <>
              <button
                onClick={() => fetchNewBulk()}
                className="allBids"
              >
                Place all bids
              </button>
            </>
          )}
          <SortingState
            sorting={sorting}
            onSortingChange={setSort}
            columnExtensions={sortingStateColumnExtensions}
          />
          <SearchState onValueChange={setSearchState} />
          <DataTypeProvider
            for={bidColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={lowestAskColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={highestBidColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={parentIdColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={nameColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={salesLast72HoursColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={brandColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={categoryColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={numberOfBidsColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <LastSaleTypeProvider for={lastSaleColumns} />
          <DataTypeProvider
            for={numberOfAsksColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={departmentColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={styleColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={colorColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={sizeColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={retailPriceColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={updatedOnColumns}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={idColumns}
            availableFilterOperations={nameFilterOperations}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={releaseYearColumns}
            availableFilterOperations={bidFilterOperations}
            editorComponent={BidEditor}
          />
          <DataTypeProvider
            for={createdOnColumns}
            editorComponent={NameEditor}
          />
          <FilteringState
            onFiltersChange={getFilters}
            columnExtensions={filteringStateColumnExtensions}
          />
          <PagingState
            currentPage={currentPage} // show current page, first time it's set to 1
            onCurrentPageChange={setNowPage} // to change current page
            pageSize={pageSize} // set page limit to show in one page
          />
          <CustomPaging totalCount={totalCount} />
          <DragDropProvider />
          <GroupingState
            grouping={grouping}
            columnExtensions={groupingStateColumnExtensions} // to control grouping with some fields
            onGroupingChange={changeGrouping} // to change grouping fields
            expandedGroups={expandedGroups} // to expand/collapse the groups selected
            onExpandedGroupsChange={setExpandedGroups} // to handle grouped rows to toggle
          />
          <CustomGrouping
            getChildGroups={getChildGroups}
            grouping={tempGrouping}
            expandedGroups={tempExpandedGroups}
          />
          {/* <ImageTypeProvider /> to show the image and while clicking it redirects to another detail page */}
          <ImageTypeProvider for={imageColumns} />
          <EditingState
            // onCommitChanges={commitChanges} // handles each actions in table row
            columnExtensions={editStateColumnExtensions} // to enable the columns to edit
          />
          <VirtualTable
            messages={data.length === 0 && tableMessages}
          />
          {/* <TableEditRow /> */}
          <TableEditColumn messages={editColumnMessages} />
          <TableHeaderRow
            showSortingControls // to control the sorting in each fields
            className="group"
            showGroupingControls // to disable grouping
          />
          <TableFilterRow
            showFilterSelector
            iconComponent={FilterIcon}
            messages={filterMessages}
          />
          <TableColumnVisibility
            defaultHiddenColumnNames={defaultHiddenColumnNames}
          />

          <TableGroupRow />
          <Toolbar />
          <TableInlineCellEditing />
          <ColumnChooser />
          <GroupingPanel
            showSortingControls // to control the sorting for grouped field
            className="group"
            showGroupingControls // to disable grouping
          />
          <SearchPanel />
          {data.length > 0 && <PagingPanel />}
        </Grid>
        <LoadingOverlay showOverlay={loading} />
      </div>
    </>
  );
};
