import React, { useReducer, useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import NumberFormat from "react-number-format";
import { Tooltip } from "reactstrap";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  TableColumnVisibility,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  DataTypeProvider,
  PagingState,
  CustomPaging
} from "@devexpress/dx-react-grid";
import Icon from "@material-ui/core/Icon";
import SweetAlert from "react-bootstrap-sweetalert";

import LoadingOverlay from "../../components/overlays/LoadingOverlay.jsx";
import {
  BULK_UPDATE_RESULTS_API_URL,
  BULK_BID_UPDATE_HISTORY_API_URL,
  BULK_UPDATE_CANCEL_OR_REVERT
} from "../../configurations/configApi.jsx";
import "react-datepicker/dist/react-datepicker.css";
import GridItem from "../../components/Grid/GridItem";

const initialState = {
  data: [],
  loading: false,
  totalCount: 0,
  errorMessage: "",
  successMessage: "",
  columns:[],
  Backbtn:false,
  statusEditor:true,
  paginationStatus:false,
};




function reducer(state, { type, payload }) {
  switch (type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: payload.results,
        totalCount: payload.count,
        loading: false,
        statusEditor:false,
        pageRunId:"",
        columns:[
          { name: "image_url", title: "Image" },
          { name: "product_title", title: "Title" },
          { name: "status", title: "Status" },
          { name: "status_message", title: "Message" },
          { name: "size", title: "Size" },
          { name: "auto_bid", title: "Bid Amount" },
          { name: "max_bid", title: "Max Bid" },
          { name: "run_id", title: "Run Id" },
          { name: "auto_bid_status", title: "Auto Bid Status" },
          { name: "created_on", title: "Created On" },
          { name: "updated_on", title: "Updated On" }
        ],
        Backbtn:true
      };
    case "FETCH_ERROR":
      return {
        ...state,
        loading: false
      };
    case "LOADING":
      return {
        ...state,
        loading: true
      };
    case "RUN_ID":
      return {
        ...state,
        runIds: payload.data
      }
    case "RUN_ID_TABLE_DATA":
      return {
        ...state,
        data: payload.results,
        totalCount: payload.count,
        statusEditor:true,
        columns:[ { name: "run_id", title: "Date" },
        { name: "status", title: "Status" },
        { name: "max_bid_amount", title: "Max Bid" },
        { name: "auto_bid_status", title: "Auto Bid Status" },
        { name: "auto_bid_amount", title: "Auto Bid Amount" },
        {name:"filter_params",title:"Filter Params"},
        {name:"no_of_products",title:"No Of Products"},
        { name: "ended_on", title: "Last Update" },],
        Backbtn:false,
        loading: false
      }
    default:
      return state;
  }
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [lastQuery, setLastQuery] = useState();
  const [runIdLastQuery,setrunIdLastQuery]= useState()
  const [initialLoad, setInitialLoad] = useState(true);
  const [sweetAlert, setSweetAlert] = useState("");
  const [imageColumns] = useState(["image_url"]);
  const [statusColumns] = useState(["status"]);
  const [messageColumns] = useState(["status_message"]);
  const [autoBidColumns] = useState(["auto_bid_status"]);
  const [pageSize] = useState(10); // to get the number of rows in each page
  const [currentPage, setCurrentPage] = useState(0);
  const [RunIdcurrentPage, setRunIdcurrentPage] = useState(0); // to get current page
  const [runIdColumns] = useState(["run_id"]);
  const [FilterParamColumn] = useState(["filter_params"])
  const [IsActiveColumn] = useState(["is_active"])
 const [pageRunId,setPageRunId] =useState("")

 const [columnWidths] = useState([
  { columnName: 'run_id', width: 150 },
  { columnName: 'status', width: 140 },
  { columnName: 'max_bid_amount', width: 120 },
  { columnName: 'auto_bid_status', width: 120 },
  { columnName: 'auto_bid_amount', width: 120 },
  { columnName: 'filter_params', width: 380 },
  { columnName: 'no_of_products', width: 140 },
  { columnName: 'ended_on', width: 150 },
  { columnName: "image_url", width: 150 },
  { columnName: "product_title", width: 150 },
  { columnName: "status",width: 150 },
  { columnName: "status_message", width: 150 },
  { columnName: "size", width: 150 },
  { columnName: "auto_bid",width: 150 },
  { columnName: "max_bid", width: 150 },
  { columnName: "run_id", width: 150 },
  { columnName: "auto_bid_status", width: 150 },
  { columnName: "created_on", width: 150 },
  { columnName: "updated_on", width: 150 }
]);

 const[paginationStatus, setpaginationStatus]=useState(false)

  const { data, loading, totalCount, columns,Backbtn,statusEditor } = state;


  // to show this message, before api for listing is called
  const tableMessages = {
    noData: "No Data Found"
  };

  const getQueryString = () => {
    return `${BULK_UPDATE_RESULTS_API_URL}?page=${currentPage + 1}`;
  };

  const getRunIdQueryString=()=>{
    return `${BULK_BID_UPDATE_HISTORY_API_URL}?page=${RunIdcurrentPage+1}`;
  }

  const fetchData = (runId) => {
    setPageRunId(runId)
    setpaginationStatus(true)
    const queryString = getQueryString();
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };
    const urlFetch = runId ? `${queryString}&run_id=${runId}` : queryString;

    axios(urlFetch, {
      method: "get",
      headers
    })
      .then(response => {
        dispatch({ type: "FETCH_SUCCESS", payload: response.data });

      })
      .catch(error => {
        Sentry.captureException("Bulk Update Fetch API " + error);
        dispatch({ type: "FETCH_ERROR" });
        const sweet = (
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Error"
            onConfirm={() => setSweetAlert("")}
            confirmBtnText="OK"
          >
            <h4>Bulk Update Tab - Error in fetching data!</h4>
          </SweetAlert>
        );
        setSweetAlert(sweet);
      });
  };


  const fetchRunIdsTableData=()=>{
    const RunIdqueryString = getRunIdQueryString();
    setpaginationStatus(false)
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

       axios(RunIdqueryString, {
        method: "get",
        headers
      })
        .then(response => {

          dispatch({ type: "RUN_ID_TABLE_DATA", payload: response.data });
        })
        .catch(error => {
          Sentry.captureException("Run Id's Fetch API " + error);
        });
  }


  // for loading the data
  const loadData = () => {
    const queryString = getQueryString();
    const RunIdqueryString = getRunIdQueryString();
    setInitialLoad(false);

    if (queryString !== lastQuery && !loading) {
      if(!paginationStatus){
        fetchRunIdsTableData();
      }else{
        fetchData(pageRunId)
      }
      setLastQuery(queryString);
    }else if(RunIdqueryString!== runIdLastQuery && !loading){
      if(!paginationStatus){
        fetchRunIdsTableData();
      }else{
        fetchData(pageRunId)
      }
      setrunIdLastQuery(RunIdqueryString);
    }
  };

  //refresh Data

  const refreshData=()=>{
    setSweetAlert("")
    if(!paginationStatus){
      fetchRunIdsTableData();
    }else{
      fetchData(pageRunId)
    }
  }

  useEffect(() => {
    if (initialLoad) {
      var projections = document.getElementsByTagName("body")[0];
      projections.classList.remove("projectIcon");
      var testElements = document.getElementsByTagName("body")[0];
      testElements.classList.remove("hideAddition");
    }
    loadData();

  });

  const ImageFormatter = ({ row, value }) => {
    return (
      <>
        <a
          href={row.product_url}
          target="_blank"
          className="productImage"
          rel="noopener noreferrer"
        >
          <img src={value} alt="" />
        </a>
      </>
    );
  };

  const ImageTypeProvider = props => (
    <DataTypeProvider formatterComponent={ImageFormatter} {...props} />
  );

  // to show custom formatting in image field in each expanded tables
  const MessageFormatter = ({ value, row }) => {
    return value === "PLACED" ? (
      <>
        {row["status_message"] !== null ? (
          <p>{row["status_message"]}</p>
        ) : (
            <p>Placed</p>
          )}
      </>
    ) : (
        <>
          {row["status_message"] !== null ? (
            <p>{row["status_message"]}</p>
          ) : (
              <p>Error</p>
            )}
        </>
      );
  };

  const RowIdFormatter = ({ value, row }) => {
     return (
     <div className="RowIdFormatter" onClick={()=>
     {fetchData(JSON.stringify(value))
     }}>
      <a href="#">{value}</a>
      </div>
    )
  };

  // capability to customize formatting
  const MessageTypeProvider = props => (
    <DataTypeProvider formatterComponent={MessageFormatter} {...props} />
  );

  const RowIdTypePrivider = props => (
    <DataTypeProvider formatterComponent={RowIdFormatter} {...props} />
  );

  // to show custom formatting in image field in each expanded tables
  const AutoBidFormatter = ({ value }) => {
    return (
      <input
        type="checkbox"
        defaultChecked={value==="True"? true : false}
        title={value==="True" ? "Active" : "Inactive"}
        disabled={true}
      />
    );
  };

  const IsActiveFormatter = ({ value }) => {
    return (
      <input
        type="checkbox"
        defaultChecked={value}
        title={value ? "Active" : "Inactive"}
        disabled={true}
      />
    );
  };

  // capability to customize formatting
  const AutoBidTypeProvider = props => (
    <DataTypeProvider formatterComponent={AutoBidFormatter} {...props} />
  );

 const IsActiveTypeProvider = props => (
  <DataTypeProvider formatterComponent={IsActiveFormatter} {...props} />
);

  // to show custom formatting in image field in each expanded tables
  const StatusFormatter = ({ value, row }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const spanRef = useRef(null);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    if(!statusEditor){
      return value === "PLACED" ? (
        <>
          {row["status_message"] !== null ? (
            <>
              <span ref={spanRef}>
                <p className="proxySuccess">
                  <Icon>check_circle</Icon>
                </p>
              </span>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={spanRef}
                toggle={toggle}
                className="customTooltip"
              >
                {row["status_message"]}
                <br />
              </Tooltip>
            </>
          ) : (
              <p className="proxySuccess" title="Placed">
                <Icon>check_circle</Icon>
              </p>
            )}
        </>
      ) : (
          <>
            {row["status_message"] !== null ? (
              <>
                <span ref={spanRef}>
                  <p className="proxyFail">
                    <Icon>error</Icon>
                  </p>
                </span>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  autohide={false}
                  target={spanRef}
                  toggle={toggle}
                  className="customTooltip"
                >
                  {row["status_message"]}

                  <br />
                </Tooltip>
              </>
            ) : (
                <p className="proxyFail" title="Error">
                  <Icon>error</Icon>
                </p>
              )}
          </>
        );
    }else{

      return value === "COMPLETED" ? (
            <>
              <span>
                <p className="proxySuccess  text-center">
                <span  ref={spanRef}><Icon>check_circle</Icon></span> <button title="Click here to Revert" className="btn btn-sm btn-default cancel-Btn" onClick={()=>showConformMsg(row, "COMPLETED")}> <Icon>keyboard_return</Icon>
                </button>
                </p>
              </span>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={spanRef}
                toggle={toggle}
                className="customTooltip"
              >
                {row["status"]}
                <br />
              </Tooltip>
            </>

      ):  value === "UNDOING" ? (
        <>
        <span ref={spanRef}>
          <p className="text-center">
            <Icon>hourglass_empty</Icon>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):value === "IN PROGRESS" ? (
        <>
        <span >
          <p className="proxySuccess  text-center">
           <span ref={spanRef}><Icon>cached</Icon> </span> <button title="Click here to Undoing" className="btn btn-sm btn-default cancel-Btn" onClick={()=>showConformMsg(row, "IN PROGRESS")}><Icon>hourglass_empty</Icon></button>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):value === "QUEUED" ? (
        <>
        <span>
          <p className="text-center ">
          <span ref={spanRef}> <Icon>dynamic_feed</Icon> </span><button title="Click here to Cancel" className="btn btn-sm btn-default cancel-Btn" onClick={()=>showConformMsg(row, "QUEUED")}>Cancel</button>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):value === "REVERTED" ? (
        <>
        <span ref={spanRef}>
          <p className="text-center">
            <Icon>keyboard_return</Icon>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):value === "CANCELLED" ? (
        <>
        <span ref={spanRef}>
          <p className="text-center">
            <Icon>cancel</Icon>
          </p>
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={spanRef}
          toggle={toggle}
          className="customTooltip"
        >
          {row["status"]}
          <br />
        </Tooltip>
      </>
      ):""
    }

  };

  // capability to customize formatting
  const StatusTypeProvider = props => (
    <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
  )


 const FilterParamFormatter=({ value,row }) => {

  if(row["filter_params"]!==null){
    let filter_by_data = []
     if(row["filter_params"].filter_by === null || row["filter_params"].filter_by===undefined){
      filter_by_data=[]
     }else{
      filter_by_data= row["filter_params"].filter_by
     }

     let data = []
     for(let datakey in row["filter_params"]){
       if(datakey !== "filter_by"&& typeof(row["filter_params"][datakey])==="string"){
         let obj={
          [datakey]:row["filter_params"][datakey]
         }
          data.push(obj)
       }
     }

     return(
    <>
    {

        filter_by_data.map((filterkey,index)=>{

          for(let filtertemp in filterkey){
            return (
            <p>{index===0?"Filter : " : ""}{filtertemp} : {filterkey[filtertemp]}</p>
            );

          }
         })
    }
    {
      data.map((datakey)=>{
        for(let datatemp in datakey){
          return (
            <>
            <p >{datatemp ==="order_by"?"Order":datatemp ==="search_by"?"Search":datatemp} : {datakey[datatemp]}</p>
            </>
          );
        }
       })
    }
    </>
  )
   }else{
     return ""
   }

  };

  const FilterParamTypeProvider = props=>(
    <DataTypeProvider formatterComponent={FilterParamFormatter} {...props} />
  )

const sendStatusUpdate =(value, bulkId)=>{

  const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

  const data = {
    "bulk_id": bulkId,
    "run_id": value,
    "cancel_or_revert": "true"
  }

  axios(BULK_UPDATE_CANCEL_OR_REVERT, {
    method: "post",
    data,
    headers
  })
    .then(response => {

    const sweet = (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => refreshData()}
        onCancel={() =>  refreshData() }
        confirmBtnText="OK"
      >
        <h4>{response.data.message}</h4>
        <CheckOutlinedIcon className="errorOutlineIcon completed" />
      </SweetAlert>
    );
    setSweetAlert(sweet);

    })
    .catch(error => {
      Sentry.captureException("bulk-update-cancel-or-revert Api " + error);

      const sweet = (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => setSweetAlert("")}
          confirmBtnText="OK"
        >
          <h4>Bulk Update Tab - Error in Updating data!</h4>
          <ErrorOutlineIcon className="errorOutlineIcon" />
        </SweetAlert>
      );
      setSweetAlert(sweet);
    });
}

const  showConformMsg=(row, option)=> {
  const value = row['run_id'];
  const bulkId = row['id']
  let sweet;

  switch(option) {
    case "QUEUED":
      sweet = (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure you want to Cancel this?"
          onConfirm={()=>sendStatusUpdate(value, bulkId)}
          showCancel
          onCancel={() =>  setSweetAlert("")}
          confirmBtnText="Ok"
        >
          <GridItem xs={12} sm={12} md={12} id="gridSweetId">
          <ErrorOutlineIcon className="errorOutlineIcon" />
          </GridItem>
        </SweetAlert>
      );
      setSweetAlert(sweet);
      break;
    case "IN PROGRESS":
      sweet = (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure you want to Undoing this?"
          onConfirm={()=>sendStatusUpdate(value, bulkId)}
          showCancel
           onCancel={() =>  setSweetAlert("")}
          confirmBtnText="Ok"
        >
          <GridItem xs={12} sm={12} md={12} id="gridSweetId">
          <ErrorOutlineIcon className="errorOutlineIcon" />
          </GridItem>
        </SweetAlert>
      );
      setSweetAlert(sweet);
      break;
    case "COMPLETED":
      sweet = (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure you want to Revert this?"
          onConfirm={()=>sendStatusUpdate(value, bulkId)}
          showCancel
           onCancel={() =>  setSweetAlert("")}
          confirmBtnText="Ok"
        >
          <GridItem xs={12} sm={12} md={12} id="gridSweetId">
          <ErrorOutlineIcon className="errorOutlineIcon" />
  
          </GridItem>
        </SweetAlert>
      );
      setSweetAlert(sweet);
      break;
  }

}

  return (
    <div className="BulkUpdate">
       {
          Backbtn &&  <button className="bulkUpdate " onClick={()=>{fetchRunIdsTableData()}}>
          Back
          </button>
        }
      <span className="reload" title="Reload"><Icon onClick={() => refreshData()} title="Reload">refresh</Icon></span>
      {sweetAlert}
      <div className="card history">
        {totalCount > 0 && (
          <span className="bulk count" title="Total Bulk Updates">
            <NumberFormat
              value={totalCount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </span>
        )}

        <Grid rows={data} columns={columns} >
          <RowIdTypePrivider for={runIdColumns}/>
          <ImageTypeProvider for={imageColumns} />
          <StatusTypeProvider for={statusColumns} />
          <MessageTypeProvider for={messageColumns} />
          <AutoBidTypeProvider for={autoBidColumns} />
          <IsActiveTypeProvider for={IsActiveColumn} />
          <FilterParamTypeProvider for ={FilterParamColumn}/>
          <PagingState
            currentPage={paginationStatus ? currentPage : RunIdcurrentPage} // show current page, first time it's set to 1
            onCurrentPageChange={paginationStatus ? setCurrentPage: setRunIdcurrentPage} // to change current page
            pageSize={pageSize} // set page limit to show in one page
          />
          <CustomPaging totalCount={totalCount} />
          <VirtualTable messages={data.length === 0 && tableMessages} />
          <TableColumnResizing columnWidths={columnWidths}/>
          <TableHeaderRow />

          <TableColumnVisibility />
          <Toolbar />
          <ColumnChooser />
          {data.length > 0 && <PagingPanel />}
        </Grid>
        <LoadingOverlay showOverlay={loading} />
      </div>
    </div>
  );
};
