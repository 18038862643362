import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "./layouts/Auth.jsx";
import RtlLayout from "./layouts/RTL.jsx";
import AdminLayout from "./layouts/Admin.jsx";
import Login from "./views/Pages/LoginPage.jsx";
import ErrorPage from "./views/Pages/ErrorPage.jsx";
import { Auth0Provider } from "@auth0/auth0-react";

import "./assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import "./assets/scss/style.css";
import Auth0validation from "./views/Pages/Auth0validation";

const hist = createBrowserHistory();

Sentry.init({ dsn: process.env.REACT_APP_SENTRY });

const domian = "accounts.stockx.com";

const clientId = "YaoWqvkntqmnjC3LZ37TfYTl035TKfXz";

const redirectUri = "https://projec4.com/auth0validation";

ReactDOM.render(
  <Auth0Provider
    domain={domian}
    clientId={clientId}
    redirectUri={redirectUri}
    scope="openid profile email offline_access"
  >
    <Router history={hist}>
      <Switch>
        <Route path="/rtl" component={RtlLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Route path="/auth/login-page" component={Login} />
        <Route path="/auth0validation" component={Auth0validation} />
        <Route path="/error" component={ErrorPage} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </Router>
  </Auth0Provider>,
  document.getElementById("root")
);
