import React, { useReducer, useState, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import {
  PagingState,
  CustomPaging,
  FilteringState,
  SortingState,
  SearchState,
  DataTypeProvider
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Toolbar,
  TableHeaderRow,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
  TableFilterRow,
  SearchPanel,
  VirtualTable
} from "@devexpress/dx-react-grid-bootstrap4";
import Icon from "@material-ui/core/Icon";

import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "../../components/overlays/LoadingOverlay.jsx";
import { PENDING_API_URL } from "../../configurations/configApi.jsx";
import "react-datepicker/dist/react-datepicker.css";

let setEnterKeyForNameEditor = false;
let setEnterKeyForPriceEditor = false;
let setEnterKeyForPurchaseEditor = false;
let searchValueNew = "";
let filterFocus;
let statusEdited = false;
let activeValue = false;
let inActiveValue = false;
let filterList = [];
let selectedDate = "";

const initialState = {
  data: [],
  loading: false,
  totalCount: 0,
  totalHistory: 0,
  errorMessage: "",
  successMessage: ""
};

const filterMessage = {
  in: "In",
  doesNotIn: "Not In",
  month: "Month equals"
};

const NameEditor = ({ value, onValueChange, row, column }) => {
  statusEdited = false;

  const handleKey = event => {
    if (event.which === 13) {
      setEnterKeyForNameEditor = true;
      onValueChange(value);
    }
  };

  const handleKeyDown = event => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === "v") {
      setEnterKeyForNameEditor = true;
    } else if (event.ctrlKey && charCode === "x") {
      setEnterKeyForNameEditor = true;
    }
  };

  const handleChange = event => {
    filterFocus = "";
    if (event.target.value === "") {
      setEnterKeyForNameEditor = true;
      onValueChange(event.target.value);
    }

    const { value: targetValue } = event.target;
    onValueChange(targetValue);
  };

  return (
    <input
      className="form-control text-right"
      type="text"
      tabIndex="0"
      placeholder="Filter..."
      value={value === undefined ? "" : value}
      min={0}
      onChange={handleChange}
      onKeyPress={e => handleKey(e)}
      onKeyDown={e => handleKeyDown(e)}
    />
  );
};

const BidWinEditor = ({ onValueChange, row }) => {
  const handleChange = event => {
    statusEdited = true;
    onValueChange(event.target.name);
    if (event.target.value === "Active" && event.target.checked) {
      activeValue = true;
    }
    if (event.target.value === "Active" && !event.target.checked) {
      activeValue = false;
    }
    if (event.target.value === "Inactive" && event.target.checked) {
      inActiveValue = true;
    }
    if (event.target.value === "Inactive" && !event.target.checked) {
      inActiveValue = false;
    }
  };

  return (
    <div className="cu-checkbox">
      <input
        type="checkbox"
        style={{ width: "30px", height: "40px" }}
        id="active"
        name="active"
        value="Active"
        checked={activeValue}
        onChange={handleChange}
        title="Auto"
      />
      <label for="active" title="Active">
        Auto
      </label>
      <input
        type="checkbox"
        style={{ width: "30px", height: "40px" }}
        id="inactive"
        name="inactive"
        checked={inActiveValue}
        value="Inactive"
        onChange={handleChange}
        title="Manual"
      />
      <label for="inactive" title="Inactive">
        Manual
      </label>
    </div>
  );
};

const PurchaseEditor = ({ value, onValueChange, row, column }) => {
  statusEdited = false;

  const handleKey = e => {
    if (e.which === 13) {
      setEnterKeyForPurchaseEditor = true;
      onValueChange(value);
    } else {
      e.preventDefault();
      return false;
    }
  };

  const handleChange = event => {
    filterFocus = "";

    selectedDate = event;
    let month = event && event.getMonth() + 1;
    const formatDate = event
      ? event.getFullYear() + "-" + month + "-" + event.getDate()
      : "";
    setEnterKeyForPurchaseEditor = true;
    onValueChange(formatDate);
  };

  return row ? (
    <p>{value}</p>
  ) : (
    <DatePicker
      className="pick-calender"
      dateFormat="MM/dd/yyyy"
      placeholderText="mm/dd/yyyy"
      selected={selectedDate}
      isClearable
      onChange={date => handleChange(date)}
      onKeyDown={event => handleKey(event)}
    />
  );
};

const PriceEditor = ({ value, onValueChange, column }) => {
  statusEdited = false;

  const handleChange = event => {
    filterFocus = event.target.id;
    if (event.target.value === "") {
      setEnterKeyForPriceEditor = true;
      onValueChange(event.target.value);
    }
    const { value: targetValue } = event.target;
    onValueChange(targetValue);
  };

  //Function to avoid negative values
  const handleKeypress = e => {
    const characterCode = e.key;
    if (e.which === 13) {
      setEnterKeyForPriceEditor = true;
      onValueChange(value);
    }
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  const handleKeyDown = event => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === "v") {
      setEnterKeyForPriceEditor = true;
    } else if (event.ctrlKey && charCode === "x") {
      setEnterKeyForPriceEditor = true;
    }
  };

  return (
    <input
      className="form-control text-right"
      type="number"
      id={column.name}
      tabIndex="0"
      placeholder="Filter..."
      value={value === undefined ? "" : value}
      min={0}
      onChange={handleChange}
      onKeyPress={e => handleKeypress(e)} //avoid negative values
      onKeyDown={e => handleKeyDown(e)}
    />
  );
};

const FilterIcon = ({ type }) => {
  if (type === "month") {
    return <span className="d-block oi oi-calendar" />;
  } else if (type === "in") {
    return <span className="d-block oi oi-magnifying-glass" />;
  } else if (type === "doesNotIn") {
    return <span className="d-block oi oi-magnifying-glass cross" />;
  }
  return <TableFilterRow.Icon type={type} />;
};

function reducer(state, { type, payload }) {
  switch (type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: payload.data,
        totalCount: payload.totalCount,
        totalHistory: payload.total_history,
        loading: false
      };
    case "FETCH_ERROR":
      return {
        ...state,
        loading: false
      };
    case "LOADING":
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [columns] = useState([
    { name: "image_url", title: "Image" },
    { name: "title", title: "Item" },
    { name: "order_number", title: "Order Number" },
    { name: "purchase_date_formatted", title: "Purchase Date" },
    { name: "total_amount", title: "Price" },
    { name: "text", title: "Status" },
    { name: "tracking__deliveryDate", title: "Delivered On" },
    { name: "is_auto_bid_win", title: "Auto Bid Win Status" },
    { name: "last_updated", title: "Updated On" }
  ]);
  const [textColumns] = useState(["text"]);
  const [deliveryDateColumns] = useState(["tracking__deliveryDate"]);
  const [purchaseDateColumns] = useState(["purchase_date_formatted"]);
  const [titleColumns] = useState(["title"]);
  const [imageColumns] = useState(["image_url"]);
  const [priceColumns] = useState(["total_amount"]);
  const [bidWinColumns] = useState(["is_auto_bid_win"]); // to display the win bid count
  const [pageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastQuery, setLastQuery] = useState();
  const [sorting, setSorting] = useState([
    { columnName: "purchase_date_formatted", direction: "desc" }
  ]); // sorting param
  const [index, setIndex] = useState(0);
  const [searchValue, setSearchValue] = useState(""); // to get search params
  const [sortIndex, setSortIndex] = useState(0);
  const [filterIndex, setFilterIndex] = useState(0);
  const [enterKeyValue, setEnterKeyValue] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [filters, setFilters] = useState([]);
  const [sweetAlert, setSweetAlert] = useState("");

  const [sortingStateColumnExtensions] = useState([
    { columnName: "text", sortingEnabled: false },
    { columnName: "order_number", sortingEnabled: false },
    { columnName: "last_updated", sortingEnabled: false },
    { columnName: "is_auto_bid_win", sortingEnabled: false },
    { columnName: "image_url", sortingEnabled: false }
  ]);
  const [filteringStateColumnExtensions] = useState([
    { columnName: "text", filteringEnabled: false },
    { columnName: "order_number", filteringEnabled: false },
    { columnName: "tracking__deliveryDate", filteringEnabled: false },
    { columnName: "is_auto_bid_win", filteringEnabled: false },
    { columnName: "last_updated", filteringEnabled: false },
    { columnName: "image_url", filteringEnabled: false }
  ]);
  const [nameFilterOperations] = useState([
    "contains",
    "notContains",
    "startsWith",
    "endsWith",
    "equal",
    "in",
    "doesNotIn"
  ]);
  const [priceFilterOperations] = useState([
    "equal",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual"
  ]);
  const [dateFilterOperations] = useState([
    "equal",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual"
  ]);

  const { data, loading, totalCount } = state;

  const setFilterNameEditor = () => {
    setFilters(enterKeyValue);
    setCurrentPage(0);
    setEnterKeyForNameEditor = false;
    setEnterKeyForPriceEditor = false;
    setEnterKeyForPurchaseEditor = false;
  };

  // to show this message, before api for listing is called
  const tableMessages = {
    noData: "No Data Found"
  };

  // to show this message on searching panel
  const searchPlaceHolder = {
    searchPlaceholder: "Search name, order number"
  };

  // sort function to override the setSorting method
  const setSort = val => {
    setSortIndex(1);
    setSorting(val);
    setCurrentPage(0);
  };

  const setSearchState = value => {
    setIndex(1);
    searchValueNew = value;
    if (value === "") {
      setSearchValue(value);
      setCurrentPage(0);
    }
  };

  const handleSearch = () => {
    setSearchValue(searchValueNew);
    setCurrentPage(0);
  };

  // function for setting current page
  const setNowPage = val => {
    setIndex(index + 1);
    setFilterIndex(filterIndex + 1);
    sortIndex === 1 && setSortIndex(sortIndex + 1);
    setCurrentPage(val);
  };

  const getCondition = (name, condition) => {
    if (name === "is_auto_bid_win") {
      return `is_auto_bid_win`;
    } else if (condition === "greaterThan") {
      return name === "purchase_date_formatted"
        ? `purchase_date__date__gt`
        : `${name}__gt`;
    } else if (condition === "greaterThanOrEqual") {
      return name === "purchase_date_formatted"
        ? `purchase_date__date__gte`
        : `${name}__gte`;
    } else if (condition === "lessThan") {
      return name === "purchase_date_formatted"
        ? `purchase_date__date__lt`
        : `${name}__lt`;
    } else if (condition === "lessThanOrEqual") {
      return name === "purchase_date_formatted"
        ? `purchase_date__date__lte`
        : `${name}__lte`;
    } else if (condition === "equal" && name === "purchase_date_formatted" ) {
      return `purchase_date__date`;
    }else if (condition === "contains") {
      return `product__parent_product__${name}__icontains`;
    } else if (condition === "notContains") {
      return `exclude|product__parent_product__${name}__icontains`;
    } else if (condition === "startsWith") {
      return `product__parent_product__${name}__istartswith`;
    } else if (condition === "endsWith") {
      return `product__parent_product__${name}__iendswith`;
    } else if (["title"].includes(name) && condition === "equal") {
      return `product__parent_product__${name}__iexact`;
    } else if (condition === "in") {
      return (
        ["text", "title"].includes(name) &&
        `product__parent_product__${name}__in`
      );
    } else if (condition === "doesNotIn") {
      return `exclude|product__parent_product__${name}__in`;
    } else {
      return `${name}`;
    }
  };

  const getQueryString = () => {
    let sortingStr;

    // check for filter
    let filter = filters.reduce((acc, { columnName, value, operation }) => {
      let filterName = getCondition(columnName, operation);
      let filterObject = {};
      if (operation === "in" || operation === "doesNotIn") {
        var listIt = [];
        var splitValues = value.split(",");
        splitValues.forEach(each => listIt.push(each.trim()));
        filterObject[filterName] = listIt;
      } else if (columnName === "is_auto_bid_win") {
        if (filterList.length === 0 || filterList.length === 2) {
          filterObject = {};
        } else {
          filterObject[filterName] =
            filterList[0] === "active" ? "True" : "False";
        }
      } else {
        filterObject[filterName] = encodeURIComponent(value.trim());
      }
      acc.push(filterObject);
      return acc;
    }, []);

    // check for search
    let search = columns.reduce(() => {
      return searchValue;
    }, []);

    // for setting current page to 1, when any functionalities done in other pages
    if (columns.length > 1) {
      search = `[${JSON.stringify(search.trim())}]`;
    }

    // check for sorting
    if (sorting.length) {
      const sortingConfig = sorting.map(({ columnName, direction }) => {
        let eachColumns =
          columnName === "purchase_date_formatted"
            ? "purchase_date"
            : columnName;
        return direction === "desc" ? `-${eachColumns}` : eachColumns;
      });
      sortingStr = JSON.stringify(sortingConfig);
    } else {
      sortingStr = JSON.stringify(["-purchase_date"]);
    }
    return `${PENDING_API_URL}?page_type=history&order_by=${sortingStr}&search=${search}&filter=${JSON.stringify(
      filter
    )}&page=${currentPage + 1}&limit=${pageSize}`;
  };

  const fetchData = () => {
    const queryString = getQueryString();
    dispatch({ type: "LOADING" });
    const headers = { Authorization: `Token ${localStorage.getItem("token")}` };

    fetch(queryString, {
      method: "get",
      headers
    })
      .then(response => response.json())
      .then(orders => {
        dispatch({ type: "FETCH_SUCCESS", payload: orders });
      })
      .catch(error => {
        Sentry.captureException("History Fetch API " + error);
        dispatch({ type: "FETCH_ERROR" });
        const sweet = (
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="Error"
            onConfirm={() => setSweetAlert("")}
            confirmBtnText="OK"
          >
            <h4>History Tab - Error in fetching data!</h4>
          </SweetAlert>
        );
        setSweetAlert(sweet);
      });
  };

  // for loading the data
  const loadData = () => {
    const queryString = getQueryString();
    setInitialLoad(false);

    if (queryString !== lastQuery && !loading) {
      fetchData();
      setLastQuery(queryString);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      setEnterKeyForNameEditor = false;
      setEnterKeyForPriceEditor = false;
      setEnterKeyForPurchaseEditor = false;
      searchValueNew = "";
      statusEdited = false;
      activeValue = false;
      selectedDate = "";
      inActiveValue = false;
      var projections = document.getElementsByTagName("body")[0];
      projections.classList.remove("projectIcon");
      var testElements = document.getElementsByTagName("body")[0];
      testElements.classList.remove("hideAddition");
    }
    loadData();
    if (document.getElementById(filterFocus)) {
      document.getElementById(filterFocus).focus();
    }
  });

  // formatting tracking status
  const TrackFormatter = ({ row }) => {
    return <p>{row.text}</p>;
  };

  // to show custom formatting in auto_bid_win field in each expanded tables
  const BidWinFormatter = ({ value }) => {
    return (
      <input
        type="checkbox"
        checked={value}
        title={value ? "Auto" : "Manual"}
      />
    );
  };

  const TrackTypeProvider = props => (
    <DataTypeProvider formatterComponent={TrackFormatter} {...props} />
  );

  // formatting delivery date
  const DeliveryDateFormatter = ({ row }) => {
    return row.tracking__deliveryDate === "None" ? (
      ""
    ) : (
      <p>{row.tracking__deliveryDate}</p>
    );
  };

  const DeliveryDateTypeProvider = props => (
    <DataTypeProvider formatterComponent={DeliveryDateFormatter} {...props} />
  );

  // formatting total amount
  const PriceFormatter = ({ value }) => {
    return <span>${value}</span>;
  };

  // formatting purchase date
  const PurchaseDateFormatter = ({ value }) => {
    return value === "None" ? "" : <p>{value}</p>;
  };

  const ImageFormatter = ({ row, value }) => {
    return (
      <>
        <a
          href={row.product_url}
          target="_blank"
          className="productImage"
          rel="noopener noreferrer"
        >
          <img src={value} alt="" />
        </a>
        {row.size && <p className="productDesc">Size: {row.size}</p>}
      </>
    );
  };

  const ImageTypeProvider = props => (
    <DataTypeProvider formatterComponent={ImageFormatter} {...props} />
  );

  // formatting title
  const NameFormatter = ({ value }) => {
    return (
      <p className="productDesc">
        <span>{value}</span>
      </p>
    );
  };

  const getFilters = val => {
    setFilterIndex(1);
    let statusDupList = [];
    if (val.length > 0 && statusEdited) {
      val.forEach(each => {
        if (each.columnName === "is_auto_bid_win") {
          if (filterList.includes(each["value"])) {
            statusDupList = filterList.filter(e => e !== each["value"]);
            filterList = statusDupList;
          } else {
            filterList.push(each["value"]);
          }
        }
        setFilters(val);
        setCurrentPage(0);
      });
    } else {
      setEnterKeyValue(val);
    }
  };

  return (
    <>
      {sweetAlert}
      <div className="card history">
        <span className="reload" title="Reload">
          <Icon color="secondary" onClick={() => fetchData()} title="Reload">
            refresh
          </Icon>
        </span>
        <div className="searchIcon">
          <Icon onClick={handleSearch} title="Search">
            search
          </Icon>
        </div>
        {totalCount > 0 && (
          <span className="history count" title="Total History">
            <NumberFormat
              value={totalCount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </span>
        )}
        {setEnterKeyForNameEditor ||
        setEnterKeyForPriceEditor ||
        setEnterKeyForPurchaseEditor
          ? setFilterNameEditor()
          : ""}
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={currentPage} // show current page, first time it's set to 1
            onCurrentPageChange={setNowPage} // to change current page
            pageSize={pageSize} // set page limit to show in one page
          />
          <CustomPaging totalCount={totalCount} />
          <SortingState
            sorting={sorting}
            onSortingChange={setSort}
            columnExtensions={sortingStateColumnExtensions}
          />
          <SearchState onValueChange={setSearchState} />
          <FilteringState
            onFiltersChange={getFilters}
            columnExtensions={filteringStateColumnExtensions}
          />
          <DataTypeProvider
            for={purchaseDateColumns}
            editorComponent={PurchaseEditor}
            formatterComponent={PurchaseDateFormatter}
            availableFilterOperations={dateFilterOperations}
          />
          <DataTypeProvider
            for={titleColumns}
            availableFilterOperations={nameFilterOperations}
            formatterComponent={NameFormatter}
            editorComponent={NameEditor}
          />
          <DataTypeProvider
            for={priceColumns}
            formatterComponent={PriceFormatter}
            editorComponent={PriceEditor}
            availableFilterOperations={priceFilterOperations}
          />
          <DataTypeProvider
            for={bidWinColumns}
            formatterComponent={BidWinFormatter}
            editorComponent={BidWinEditor}
          />
          <ImageTypeProvider for={imageColumns} />
          <TrackTypeProvider for={textColumns} />
          <DeliveryDateTypeProvider for={deliveryDateColumns} />
          <VirtualTable messages={data.length === 0 && tableMessages} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow
            showFilterSelector
            iconComponent={FilterIcon}
            messages={filterMessage}
          />
          <TableColumnVisibility />
          <Toolbar />
          <ColumnChooser />
          <SearchPanel messages={searchPlaceHolder} />
          {data.length > 0 && <PagingPanel />}
        </Grid>
        <LoadingOverlay showOverlay={loading} />
      </div>
    </>
  );
};
